import { action } from 'typesafe-actions';

import {
  ActionTypes,
  AddressingStructureResponse,
  AddressingViewModes,
  MediaDeny,
  MediaDenyApplyResult
} from './types';

export const fetchAddressingStructureRequest = (
  channelId: number,
  workgroupId: number
) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST, {
    channelId,
    workgroupId,
  });

export const fetchAddressingStructureSuccess = (
  channelId: number,
  workgroupId: number,
  addressingStructure: AddressingStructureResponse[]
) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS, {
    channelId,
    workgroupId,
    addressingStructure,
  });

export const fetchAddressingStructureError = (err: string) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR, err);

export const fetchAddressingRulesRequest = (
  channelId: number,
  mediaId: number
) => action(ActionTypes.FETCH_ADDRESSING_RULES_REQUEST, { channelId, mediaId });

export const fetchAddressingRulesSuccess = (
  channelId: number,
  mediaId: number,
  addresingRules: MediaDeny[]
) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS, {
    channelId,
    mediaId,
    addresingRules,
  });

export const updateAddressingTreeStart = () =>
  action(ActionTypes.UPDATE_ADDRESSING_TREE);

export const clearAddressingStructure = () =>
  action(ActionTypes.CLEAR_ADDRESSING_STRUCTURE);

export const clearAddressingFull = () =>
  action(ActionTypes.CLEAR_ADDRESSING_FULL);

export const fetchAddressingRulesError = (err: string) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_ERROR, err);

export const saveAddressingRules = () =>
  action(ActionTypes.SAVE_ADDRESSING_RULES);

export const buildAddressingTreeStart = (addressingStructure: AddressingStructureResponse[], workgroupId: number) =>
  action(ActionTypes.BUILD_ADDRESSING_TREE, {addressingStructure, workgroupId});
export const toggleNodeExpand = ($$hashKey: string) =>
  action(ActionTypes.TOGGLE_NODE, $$hashKey);

export const processAddressingRulesStart = (addressingRules: MediaDeny[], mediaId: number) =>
  action(ActionTypes.PROCESS_ADDRESSING_RULES_START, { addressingRules, mediaId} );
export const processAddressingRulesEnd = (
  processedRules: MediaDenyApplyResult
) => action(ActionTypes.PROCESS_ADDRESSING_RULES_END, processedRules);
export const clearAddressingRules = () =>
  action(ActionTypes.CLEAR_ADDRESSING_RULES);

export const subscribeToWebWorker = () =>
  action(ActionTypes.SUBSCRIBE_TO_WEB_WORKER);

export const setAddressingViewMode = (viewMode: AddressingViewModes) =>
  action(ActionTypes.SET_ADDRESSING_VIEW_MODE, viewMode);
