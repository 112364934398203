import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      flexGrow: 1,
      overflow: 'auto',
      marginTop: theme.spacing(1),
    },
    searchBox: {
      marginTop: theme.spacing(2),
    },
    inline: {
      display: 'inline',
    },
    skeletonContainer: {
      marginTop: theme.spacing(2),
    },
    skeletonItem: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
      position: 'relative',
    },
    skeletonDivider: {
      marginTop: theme.spacing(2),
    },
    skeletonCircle: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(3),
      transform: 'translate(0,-50%)',
    },
  })
);
