import AddressingService from 'addressing/addressingService';
import { useAddressingContextChannelId, useAddressingContextMediaId } from 'addressing/context/hooks';
import { clearAddressingRules, processAddressingRulesStart } from 'addressing/duck/actions';
import deepClone from 'lodash-es/cloneDeep';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchAddressingRules,
  MediaDeny,
  selectAddressingRules,
  selectAddressingState,
  TreeItemType,
  TreeNode,
} from '../duck';
import { fetchAddressingRulesSuccess, processAddressingRulesEnd, subscribeToWebWorker } from '../duck/actions';

const useAddressingRules = (channelId: number = 0, mediaId: number = 0, mediaDenies?: MediaDeny[]) => {
  const dispatch = useDispatch();
  const addressingRulesState = useSelector(selectAddressingRules);
  const addressingState = useSelector(selectAddressingState);
  const contextChannelId = useAddressingContextChannelId();
  const contextMediaId = useAddressingContextMediaId();
  channelId = channelId ? channelId : contextChannelId;
  mediaId = mediaId ? mediaId : contextMediaId;

  const applyRules = useCallback(
    (newDenies: MediaDeny[]) => {
      dispatch(processAddressingRulesStart(newDenies, mediaId));
    },
    [mediaId]
  );

  const onRuleChanged = useCallback(
    (item: TreeNode) => {
      if (item.chckState === 4) {
        return;
      }

      if (item.modelType === TreeItemType.FOLDER) {
        return;
      }

      const newDenies = deepClone(addressingRulesState.rawData);

      const channelMediaDenyInfo: MediaDeny = {
        idMediaChannel: channelId,
        idMedia: mediaId,
        idEntity: Number(item.cleanId),
        idEntityType: item.entityType,
        deny: item.chckState === 0 || item.chckState === 3 ? false : item.chckState === 1 ? true : null,
      };

      let idx = newDenies.findIndex(
        (addrRule) =>
          addrRule.idEntity === channelMediaDenyInfo.idEntity &&
          addrRule.idMedia === channelMediaDenyInfo.idMedia &&
          addrRule.idEntityType === channelMediaDenyInfo.idEntityType
      );

      if (channelMediaDenyInfo.deny === null && idx > -1) {
        // this sets the deny rule to null which will trigger a delete on the server (a.k.a. it will inherit)
        newDenies[idx] = channelMediaDenyInfo;
        applyRules(newDenies);
        return;
      }

      // update - deny has value true | false
      if (idx === -1) {
        newDenies.push(channelMediaDenyInfo);
      } else {
        newDenies[idx] = channelMediaDenyInfo;
      }
      applyRules(newDenies);
    },
    [addressingRulesState.rawData, mediaId, channelId]
  );

  // subscribe to the Web Worked so he can send us back the processed rules
  useEffect(() => {
    if (addressingState.subscribedToWebWorker) {
      return;
    }

    AddressingService.subscribe((result) => {
      dispatch(processAddressingRulesEnd(result));
    });

    dispatch(subscribeToWebWorker());
  }, [addressingState.subscribedToWebWorker]);

  // effect used for initial data retrieval and cleanup when selecting a different media
  useEffect(() => {
    const isFetching = addressingRulesState.fetchStatus.isFetching;
    const fetchComplete = addressingRulesState.fetchStatus.complete;

    // we need to fetch addressing rules
    if (!isFetching && !fetchComplete) {
      // if we provided mediaDenies from the outside, just use those
      if (mediaDenies) {
        dispatch(fetchAddressingRulesSuccess(channelId, mediaId, mediaDenies));
        return ;
      } else {
        // if we didn't provide deny rules from the outside, go and fetch them from the server
        dispatch(fetchAddressingRules(channelId, mediaId));
      }
    } else {
      // if mediaId has changed, clear addressingRules state to trigger fetchAddressingRules
      if (mediaId !== addressingState.mediaId) {
        dispatch(clearAddressingRules());
      }
    }
  }, [
    addressingRulesState.fetchStatus.complete,
    addressingRulesState.fetchStatus.isFetching,
    addressingState.mediaId,
    mediaId,
    channelId,
  ]);

  return {
    processingStatus: addressingRulesState.processingStatus,
    fetchStatus: addressingRulesState.fetchStatus,
    rawData: addressingRulesState.rawData,
    processedData: addressingRulesState.processedData,
    mediaId: mediaId,
    onAddressingChange: onRuleChanged,
  };
};

export default useAddressingRules;
