import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      flexWrap: 'nowrap',
    },
    grid: {
      height: '100%',
      flexGrow: 1,
      alignItems: 'stretch',
    },
    gridItem: {
      padding: theme.spacing(2),
      position: 'relative',
      maxHeight: '100%',
      minHeight: 0,
    },
    gridItemPaper: {
      padding: theme.spacing(2),
      position: 'relative',
      height: '100%',
    },
    skeletonItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    emptyPlaylistEditContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    loadingContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  })
);
