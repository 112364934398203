import { MSG } from 'App.bootstrap';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Hidden, LinearProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleIcon from '@material-ui/icons/PlayCircleOutline';

export interface MessagePreviewProps {
  contentId: number | undefined;
  contentFilePath: string | undefined;
  variant: 'small' | 'large';
}

export const MessagePreview: React.FunctionComponent<MessagePreviewProps> = React.memo((props) => {
  const [t] = useTranslation();
  const { contentId } = props;
  const audioRef = createRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [onPlayError, setOnPlayError] = useState(false);

  const onEnded = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    return () => {
      if (!audioRef.current) {
        return;
      }
      audioRef.current?.pause();
    };
  }, [audioRef, audioRef.current, contentId]);

  const onPlayClick = useCallback(() => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      var promise = audioRef.current?.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
          })
          .catch((error) => {
            console.log('no message found');
            setOnPlayError(true);
            setIsPlaying(false);
          });
      }
    }
    setIsPlaying(!isPlaying);
  }, [audioRef, audioRef.current, isPlaying, setIsPlaying]);

  useEffect(() => {
    if (contentId) {
      setIsPlaying(false);
      setOnPlayError(false);
    }
  }, [contentId]);

  if (!props.contentId) {
    return null;
  }

  return (
    <>
      {props.variant === 'large' ? (
        <Button
          variant="contained"
          color="secondary"
          startIcon={onPlayError ? <ErrorOutlineIcon /> : isPlaying ? <PauseIcon /> : <PlayCircleIcon />}
          onClick={onPlayClick}
        >
          {isPlaying ? t('pauseMessage') : t('playMessage')}
          {/* <LinearProgress value={80} variant="determinate"/> */}
        </Button>
      ) : (
        <IconButton edge="end" onClick={onPlayClick}>
          {onPlayError ? <ErrorOutlineIcon /> : isPlaying ? <PauseIcon /> : <PlayCircleIcon />}
        </IconButton>
      )}
      <audio
        preload="none"
        onEnded={onEnded}
        ref={audioRef}
        src={`${process.env.REACT_APP_ROOT_DOMAIN}v5/medias/${contentId}/parts/${contentId}.mp3`}
      />
    </>
  );
});
