import classNames from 'classnames';
import { DirtyActions, useDirty } from 'dirty';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MessageType } from '@models';

import { MessageEdit } from '../edit/MessageEdit';
import { Navigator } from '../navigator';
import { EmptySelection } from './EmptySelection';
import { useStyles } from './MessageMasterDetails.jss';

export interface MessageMasterDetailsProps {
  selectedMessageType?: MessageType;
}

export const MessageMasterDetails: React.FunctionComponent<MessageMasterDetailsProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [selected, setSelected] = React.useState(0);
  const [nextSelected, setNextSelected] = React.useState(0);

  const onDirtyConfirm = useCallback(() => {
    setSelected(nextSelected);
    setNextSelected(0);
  }, [setSelected, setNextSelected, nextSelected]);


  const onDirtyCancel = useCallback(() => {
    setNextSelected(0);
  }, [setNextSelected]);

  const [dirty, openDialog] = useDirty(onDirtyConfirm, onDirtyCancel);

  useEffect(() => {
    setSelected(0);
  }, [props.selectedMessageType]);

  const onSelection = useCallback(
    (id: number) => {
      if (dirty) {
        openDialog(undefined, t('message.discardChangesPrompt.content'));
        setNextSelected(id);
      } else {
        setSelected(id);
      }
    },
    [dirty, selected, setSelected]
  );

  return (
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={8} className={classes.gridItemContent}>
        <Navigator variant="table" selected={selected} onSelection={onSelection} />
      </Grid>
      <Grid item xs={4} className={classes.gridItemContent}>
        <Paper
          className={classNames(classes.gridItemPaper, {
            [classes.gridItemEmptySelection]: !selected,
          })}
        >
          {selected ? <MessageEdit id={selected} /> : <EmptySelection />}
        </Paper>
      </Grid>
    </Grid>
  );
};
