import { DirtyActions } from 'dirty';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useDirty } from './useDirty';

export const useNavigationHandle = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const onConfirm = useCallback(() => {
        // this is not ideal
        // but i can't figure an easy way to detect
        // if have to go back or forward
        history.goBack();
    }, [history]);

    const [dirty, openDialog] = useDirty(onConfirm, undefined);

    useEffect(() => {
        const unblock = history.block((_: any, action: any) => {
            const inV6 = window.location.href.indexOf("v6") !== -1;
            const otherLocationInV6 = window.location.hash.indexOf('messaging') === -1;
            const browserAction = action === 'POP';

            if (!browserAction) {
                return;
            }

            if (inV6 && otherLocationInV6) {
                // pass through if go outside of messaging app
                dispatch(DirtyActions.setDirty(false));
                return;
            }

            if (dirty) {
                openDialog();
                return false;
            }
        });

        return unblock;
    }, [history, dispatch, dirty]);

    return;
}