import i18next from 'i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeTree, TreeWalker, TreeWalkerValue } from 'react-vtree';

import { useStyles } from './AddressingTree.jss';
import { AddressingNodeRenderer } from './components/AddressingNode';
import {
  AddressingViewModes,
  MediaDeny,
  NodeMeta,
  selectInitializationComplete,
  selectNeedToUpdateTree,
  TreeData,
  TreeNode,
} from './duck';
import { processAddressingRulesStart, setAddressingViewMode, updateAddressingTreeStart } from './duck/actions';
import useAddressingRules from './hooks/addressingRules';
import useAddressingStructure from './hooks/addressingStructure';

interface AdressingTreeProps {
  viewMode: AddressingViewModes;
  workgroupId?: number;
  channelId?: number;
  mediaId?: number;
  style?: React.CSSProperties;
  placeholder: JSX.Element;
  nodeRenderer: AddressingNodeRenderer;
  mediaDenies?: MediaDeny[];
}

const AddressingTree: React.FunctionComponent<AdressingTreeProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tree = useRef<FixedSizeTree<TreeData>>(null);
  const addressingStructure = useAddressingStructure(props.channelId, props.workgroupId);
  const addressingRules = useAddressingRules(props.channelId, props.mediaId, props.mediaDenies);
  const needToUpdateTree = useSelector(selectNeedToUpdateTree);
  const initializationComplete = useSelector(selectInitializationComplete);

  const getNodeData = useCallback(
    (node: TreeNode, nestingLevel: number): TreeWalkerValue<TreeData, NodeMeta> => {
      return {
        data: {
          id: node.$$hashKey,
          isLeaf: node.children.length === 0,
          isOpenByDefault: node.isExpanded,
          label: node.label,
          nestingLevel,
          isExpanded: node.isExpanded,
          chckState: node.chckState,
          modelType: node.modelType,
          isLinked: node.linked ? true : false,
          isProcessing: addressingRules.processingStatus.isProcessing,
          onToggle: () => addressingStructure.toggleExpandNode(node),
          onChange: () => addressingRules.onAddressingChange(node),
        },
        nestingLevel,
        node,
      };
    },
    [addressingStructure.processedData, addressingRules.processingStatus.isProcessing]
  );

  const treeWalker = useCallback(
    function* (): ReturnType<TreeWalker<TreeData, NodeMeta>> {
      // Workgroup View
      //yield getNodeData(addressingStructure.processedData[viewMode][0], 0);

      // A-Z view
      for (let i = 0; i < addressingStructure.processedData[props.viewMode].length; i++) {
        yield getNodeData(addressingStructure.processedData[props.viewMode][i], 0);
      }

      // Groups view
      // for (let i =0; i < addressingStructure.processedData[viewMode].length; i++) {
      //   yield getNodeData(addressingStructure.processedData[viewMode][i], 0);
      // }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      while (true) {
        const parentMeta = yield;
        if (parentMeta.node.isExpanded) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < parentMeta.node.children.length; i++) {
            yield getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);
          }
        }
      }
    },
    [addressingStructure.processedData, props.viewMode, addressingRules.processingStatus.isProcessing]
  );

  useEffect(() => {
    dispatch(setAddressingViewMode(props.viewMode));
  }, [props.viewMode]);

  // effect used to process addressing rules once the Web Worker and the tree structure have been initialized
  useEffect(() => {
    const canProcessAddressingRules =
      addressingStructure.processingStatus.complete && addressingRules.fetchStatus.complete;
    const rulesInitializationComplete =
      addressingRules.processingStatus.isProcessing || addressingRules.processingStatus.complete;

    if (canProcessAddressingRules && !rulesInitializationComplete) {
      console.info('processAddressingRulesStart');
      dispatch(processAddressingRulesStart(addressingRules.rawData, addressingRules.mediaId));
    }
  }, [
    addressingRules.fetchStatus.complete,
    addressingRules.processingStatus,
    addressingStructure.processingStatus.complete,
  ]);

  // effect used to update the addressing tree when something (a rule) changes
  useEffect(() => {
    const canUpdateAddressingTree =
      addressingRules.processingStatus.complete && addressingStructure.processingStatus.complete;
    if (needToUpdateTree && canUpdateAddressingTree) {
      dispatch(updateAddressingTreeStart());
    }
  }, [
    addressingRules.processedData,
    addressingRules.processingStatus.complete,
    addressingStructure.processedData,
    addressingStructure.processingStatus.complete,
    needToUpdateTree,
  ]);

  if (initializationComplete) {
    if (addressingStructure.processedData[props.viewMode].length < 1) {
      return (
        <div className={classes.noDataContainer}>
          <div className={classes.noDataText}>{i18next.t('addressing.noDataForViewMode')}</div>
        </div>
      );
    }
    return (
      <div style={props.style}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeTree ref={tree} treeWalker={treeWalker} itemSize={48} height={height} width={'100%'}>
              {props.nodeRenderer}
            </FixedSizeTree>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return props.placeholder;
  }
};

export default AddressingTree;
