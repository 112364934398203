import { FormListCollapseItem } from 'components/FormListCollapseItem';
import { useField } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';
import { DateTimeUtils } from 'utils';
import {
  getHMSFromMS,
  getMSFromHMS,
  getValueWithLimits
} from 'utils/dateTimeUtils';

import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import Timer from '@material-ui/icons/Timer';

import { useStyles } from './FormMediaDurationListItem.jss';

const useHMS = (initialHours: number = 0, initialSeconds: number = 0, initialMinutes: number = 0) => {
  const [hours, setHours] = useState(initialHours);
  const [minutes, setMinutes] = useState(initialSeconds);
  const [seconds, setSeconds] = useState(initialMinutes);

  const setHMS = useCallback((newHours: number, newMinutes: number, newSeconds: number) => {
    setHours(newHours);
    setMinutes(newMinutes);
    setSeconds(newSeconds);
  }, []);

  const resetHMS = useCallback(() => {
    setHours(initialHours);
    setMinutes(initialSeconds);
    setSeconds(initialMinutes);
  }, [initialHours, initialMinutes, initialSeconds]);

  return {
    hours,
    minutes,
    seconds,
    setHMS,
    resetHMS,
  };
};

export const FormMediaDurationListItem: React.FunctionComponent<{
  trueDuration?: number;
}> = ({ trueDuration }: { trueDuration?: number }) => {
  const [field, meta, helpers] = useField('duration');
  const classes = useStyles();
  const [t] = useTranslation();

  const { hours, minutes, seconds, setHMS, resetHMS } = useHMS(0, 0, 0);

  const [hasDuration, setHasDuration] = useState(false);

  useEffect(() => {
    if (field && field.value !== undefined) {
      const [newHours, newMinutes, newSeconds] = getHMSFromMS(field.value);
      setHMS(newHours, newMinutes, newSeconds);
      setHasDuration(true);
    }

    if (field.value === 0) {
      resetHMS();
    }

    if (field.value === undefined) {
      resetHMS();
      setHasDuration(false);
    }
  }, [field.value]);

  const setFieldValue = useCallback(
    (newHours: number, newMinutes: number, newSeconds: number): void => {
      let actualHours = 0;
      let actualMinutes = 0;
      let actualSeconds = 0;

      if (trueDuration) {
        const [trueDurationHours, trueDurationMinutes, trueDurationSeconds] = getHMSFromMS(trueDuration);

        actualHours = getValueWithLimits(newHours, 0, trueDurationHours);
        actualMinutes = getValueWithLimits(newMinutes, 0, newHours < trueDurationHours ? 59 : trueDurationMinutes);
        actualSeconds = getValueWithLimits(newSeconds, 0, newMinutes < trueDurationMinutes ? 59 : trueDurationSeconds);
      } else {
        actualHours = getValueWithLimits(newHours, 0, 23);
        actualMinutes = getValueWithLimits(newMinutes, 0, 59); 
        actualSeconds = getValueWithLimits(newSeconds, 0, 59);
      }

      const newDuration = getMSFromHMS(actualHours, actualMinutes, actualSeconds);

      setHMS(1, 1, 1);

      setTimeout(() => {
        setHMS(actualHours, actualMinutes, actualSeconds);
      }, 0);

      if (trueDuration && trueDuration < newDuration) {
        helpers.setValue(trueDuration);
      } else {
        helpers.setValue(newDuration);
      }
      helpers.setTouched(true);
    },
    [trueDuration]
  );

  useEffect(() => {
    if (!hasDuration) {
      helpers.setValue(undefined);
    }
  }, [hasDuration]);

  useEffect(() => {
    if (field.value !== undefined) {
      setHasDuration(true);
    }
  }, [field.value]);

  const formattedDuration = useMemo(() => {
    if (field.value && trueDuration) {
      return (
        <>
          <Typography
            component="span"
            color={field.value && field.value > trueDuration ? 'error' : 'textPrimary'}
            variant="body1"
          >
            {`${DateTimeUtils.secondsFormat(field.value ? field.value : 0)}`}
          </Typography>
          <Typography component="span" variant="body1" color="textPrimary">
            {' '}
            / {`${DateTimeUtils.secondsFormat(trueDuration ? trueDuration : 0)}`}
          </Typography>
        </>
      );
    }

    if (field.value && !trueDuration) {
      return (
        <>
          <Typography color="textPrimary" variant="body1" component="span">
            {`${DateTimeUtils.secondsFormat(field.value)}`} -{' '}
          </Typography>
          <Typography color="error" variant={'body1'} component="span">
            {t('media.duration.error.unavailable')}
          </Typography>
        </>
      );
    }

    if (!field.value && !trueDuration) {
      return (
        <>
          <Typography color="error" variant={'body1'}>
            {t('media.duration.error.unavailable')}
          </Typography>
        </>
      );
    }

    if (trueDuration) {
      return (
        <Typography color="textPrimary" variant={'body1'}>{`${DateTimeUtils.secondsFormat(trueDuration)}`}</Typography>
      );
    }

    return '';
  }, [trueDuration, field.value]);

  return (
    <FormListCollapseItem
      labelKey={'duration'}
      noValueKey="durationUnavailable"
      value={formattedDuration}
      icon={<Timer />}
    >
      <List disablePadding>
        <ListItem>
          <ListItemText
            inset
            primaryTypographyProps={{component: 'div'}}
            primary={
              <div className={classes.durationWrapper}>
                <FormControlLabel
                  className={classes.checkbox}
                  control={<Checkbox color="secondary" />}
                  label={t('media.duration')}
                  checked={hasDuration}
                  onChange={(_, checked: boolean) => setHasDuration(checked)}
                />

                {hasDuration && (
                  <div className={classes.intervalWrapper}>
                    <TextFieldInnerWrapper
                      fullWidth
                      label={t('media.duration.hours')}
                      type={'number'}
                      className={classes.field}
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 23,
                        },
                      }}
                      variant="outlined"
                      value={hours.toString()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(parseInt(event.target.value || '0'), minutes, seconds)
                      }
                    />

                    <TextFieldInnerWrapper
                      fullWidth
                      label={t('media.duration.minutes')}
                      type={'number'}
                      variant="outlined"
                      className={classes.field}
                      value={minutes.toString()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(hours, parseInt(event.target.value || '0'), seconds)
                      }
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 59,
                        },
                      }}
                    />

                    <TextFieldInnerWrapper
                      fullWidth
                      variant="outlined"
                      label={t('media.duration.seconds')}
                      className={classes.field}
                      type={'number'}
                      value={seconds.toString()}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue(hours, minutes, parseInt(event.target.value || '0'))
                      }
                      InputProps={{
                        inputProps: {
                          min: 0,
                          max: 59,
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            }
          />
        </ListItem>
      </List>
    </FormListCollapseItem>

    // <ListItem>
    //   <ListItemIcon>
    //     <Timer />
    //   </ListItemIcon>
    //   <ListItemText>
    //
    //   </ListItemText>
    // </ListItem>
  );
};
