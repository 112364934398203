import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';
import { MediaDto } from '@models';

import { ZoneSelectors } from '../duck';
import { useStyles } from './Playlists.jss';

export interface PlaylistsProps {
  onSelected?: (id: number) => void;
  selected?: number;
  zoneId: number;
}

const PlaylistsSkeleton: React.FunctionComponent = React.memo(() => {
  return (
    <div>
      <div>
        <Skeleton variant="text" width={120} />
        <Skeleton variant="text" width={250} />
        <Skeleton variant="text" width={20} />
        <Divider />
      </div>
      <div>
        <Skeleton variant="text" width={180} />
        <Skeleton variant="text" width={190} />
        <Skeleton variant="text" width={70} />

        <Divider />
      </div>
      <div>
        <Skeleton variant="text" width={90} />
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={50} />
        <Divider />
      </div>
    </div>
  );
});

const PlaylistsEmpty = React.memo(() => {
  const [t] = useTranslation();

  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {t('playlists.emptyList')}
    </Typography>
  );
});

export const Playlists: React.FunctionComponent<PlaylistsProps> = (props) => {
  const { selected, onSelected } = props;
  const classes = useStyles();
  const history = useHistory();
  const playlists = useSelector(ZoneSelectors.selectZonePlaylists);
  const isFetchingZonePlaylists = useSelector(ZoneSelectors.selectFetchingZonePlaylists);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const id = Number(e.currentTarget.id);
      const eventPlaylist = playlists.find((ep) => ep.id === id);

      if (!eventPlaylist) {
        return;
      }

      if (onSelected) {
        onSelected(id);
      } else {
        history.push(`/zone/${props.zoneId}/${id}`);
      }
    },
    [onSelected, history, playlists]
  );

  if (isFetchingZonePlaylists) {
    return <PlaylistsSkeleton />;
  }

  if (playlists.length === 0) {
    return <PlaylistsEmpty />;
  }

  return (
    <List className={classes.list}>
      {playlists.map((e) => (
        <ListItem button divider key={e.id} id={e.id.toString()} selected={e.id === selected} onClick={handleClick}>
          <ListItemText primary={e.name} />
        </ListItem>
      ))}
    </List>
  );
};
