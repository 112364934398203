import { useField } from 'formik';
import React from 'react';
import { TextFieldInnerWrapper } from 'react-tools';

import { ListItem, ListItemText } from '@material-ui/core';

import { useStyles } from './FormMediaNameListItem.jss';

export const FormMediaNameListItem: React.FunctionComponent = (props) => {
  const [field, meta] = useField('name');
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemText inset>
        <TextFieldInnerWrapper
          fullWidth
          name="name"
          value={field.value}
          InputProps={{
            classes: {
              input: classes.nameField,
            },
          }}
          inputProps={{
            maxLength: 255,
          }}
          onChange={field.onChange}
          error={meta.error !== undefined}
          helperText={meta.error}
        />
      </ListItemText>
    </ListItem>
  );
};
