import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      flexGrow: 1,
      overflow: 'auto',
    },
    dragHandle: {
      cursor: 'grab',
    },
  })
);
