import { saveAddressingRules } from 'addressing/duck/actions';
import { MSG } from 'App.bootstrap';
import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';

import { MediaDto, MessageType, PlaylistUpdateDto } from '@models';
import { ZonePlaylistDto } from '@models/PlaylistDto';

import { MediaDeny } from '../../addressing';
import { AddressingApi } from '../../addressing/duck';
import * as actions from './actions';
import { ZonesApi } from './api';
import { AppState } from 'store';
import { LayoutSelectors } from 'layout';

export const fetchZones = (workgroupId: number = MSG.workgroupId) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchZonesRequest(workgroupId));
    try {
      const zones = await ZonesApi.getZones(workgroupId);
      dispatch(actions.fetchZonesSuccess(workgroupId, zones.overhead, zones.onHold));
    } catch (err) {}
  };
};

export const fetchZonePlaylists = (zoneId: number) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    const messageType = LayoutSelectors.selectMessageType(getState()) || MessageType.Overhead;
    dispatch(actions.fetchZonePlaylistsRequest(zoneId));
    try {
      const playlists = await ZonesApi.getZonePlaylists(zoneId, messageType);
      dispatch(actions.fetchZonePlaylistsSuccess(zoneId, playlists));
    } catch (err) {
      console.log(err);
    }
  };
};

export const fetchPlaylistInfo = (playlistId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchPlaylistInfoRequest(playlistId));
    try {
      const data = await ZonesApi.getPlaylistInfo(playlistId);
      dispatch(actions.fetchPlaylistInfoSuccess(playlistId, data.playlist, data.messages));
    } catch (err) {
      dispatch(actions.fetchPlaylistInfoFail(err));
      dispatch(pushNotification(err, 'warning'));
    }
  };
};

export const savePlaylist = (
  zoneId: number,
  playlistInfoDto: PlaylistUpdateDto,
  messages: MediaDto[],
  addressing: MediaDeny[]
) => {
  return async (dispatch: Dispatch<any>, getState: () => AppState) => {
    try {
      const state = getState();
      const messageType = LayoutSelectors.selectMessageType(state);
      const position = state.zone.selectedPlaylistMessages.length;
      dispatch(actions.savePlaylistRequest());
      const response = await ZonesApi.savePlaylist(
        zoneId,
        playlistInfoDto,
        messages,
        position,
        messageType || MessageType.Overhead
      );

      // this playlist has just been created, we need to add it's newly generated id to the addressing rules before saving
      if (!playlistInfoDto.id && response) {
        addressing.forEach((addressing) => (addressing.idMedia = response.playlistInfo.id));
      }

      await AddressingApi.updateAddressing(addressing);
      dispatch(saveAddressingRules());
      dispatch(
        actions.savePlaylistSuccess((response ? response.playlistInfo : playlistInfoDto) as ZonePlaylistDto, messages)
      );
      dispatch(pushNotification(i18next.t('playlist.success.saveMessage'), 'success'));
    } catch (error) {
      dispatch(pushNotification(i18next.t('playlist.error.saveMessage'), 'error'));
      dispatch(actions.savePlaylistFail());
    }
  };
};

export const deleteZonePlaylist = (zoneId: number, playlistId: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.deleteZonePlaylistRequest(playlistId));
      await ZonesApi.deletePlaylist(zoneId, playlistId);
      dispatch(actions.deleteZonePlaylistSuccess(playlistId));
      dispatch(pushNotification(i18next.t('playlist.success.deletePlaylist'), 'success'));
    } catch (error) {
      dispatch(actions.deleteZonePlaylistFail());
      dispatch(pushNotification(i18next.t('playlist.error.deletePlaylist'), 'error'));
    }
  };
};
