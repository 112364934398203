export const dirtyAreaName = 'dirty';

export enum ActionTypes {
    SET_DIRTY = 'SET_DIRTY',
    OPEN_DIALOG = 'OPEN_DIALOG',
    CLEAR_DIALOG = 'CLEAR_DIALOG',
    CONFIRM_CLICKED = 'CONFIRM_CLICKED',
    CANCEL_CLICKED = 'CANCEL_CLICKED'
}

export interface DirtyState {
    value: boolean,
    dialog: {
        open: boolean,
        confirmClicked: boolean,
        cancelClicked: boolean,
        title: string,
        content: string,
        issuer: string,
    }
}
