import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(-2),
      },
    },
    listForm: {
      flexGrow: 1,
      minHeight: 0,
      overflowY: 'auto',
    },
    rangeArrow: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);
