import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { ListMediaItem } from '../types';
import { useStyles } from './SortableList.jss';
import classes from '*.module.css';

interface SortableListContainerProps {
  items: ListMediaItem[];
  selectedIds: number[];
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface SortableItemProps {
  item: ListMediaItem;
  selected: boolean;
  itemPosition: number;
  totalCount: number;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DragHandle = SortableHandle(() => {
  const classes = useStyles();

  return (
    <ListItemIcon className={classes.dragHandle}>
      <DragHandleIcon />
    </ListItemIcon>
  );
});

const SortableItem = SortableElement<SortableItemProps>((props: SortableItemProps) => (
  <ListItem button divider={props.itemPosition < props.totalCount -1} onClick={props.onClick} data-id={props.item.localId} component="div">
    <ListItemIcon>
      <Checkbox
        edge="start"
        checked={props.selected}
        tabIndex={-1}
        disableRipple
        // inputProps={{ 'aria-labelledby': labelId }}
      />
    </ListItemIcon>
    <ListItemText primary={props.item.name} />
    <ListItemSecondaryAction>
      <DragHandle />
    </ListItemSecondaryAction>
  </ListItem>
));

export const SortableListContainer = SortableContainer((props: SortableListContainerProps) => {
  const classes = useStyles();
  return (
    <List component="div" className={classes.list}>
      {props.items.map((item, index, a) => (
        <SortableItem
          key={`${item.id}-${index}`}
          itemPosition={index}
          index={index}
          item={item}
          totalCount={a.length}
          selected={props.selectedIds.indexOf(item.localId) !== -1}
          onClick={props.onClick}
        />
      ))}
    </List>
  );
});
