import { MediaDto } from './MediaDto';

export enum EventType {
  AsSoonAs = 1,
  ExactTime,
  PauseMedia,
  Overlay,
}

export enum RepeatFrequency {
  NoRepeat = 0,
  RepeatDaily,
  RepeatHourly,
  RepeatAfterNumberOfTracks,
}

export interface ZonePlaylistDto extends MediaDto {
  eventType: EventType;
  repeatFrequency: RepeatFrequency;
  repeatNumberOfTracks?: number;
  repeatInterval?: string;
  isRandom: boolean;
  maxNoOfElements: number;
}
