import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: '100%',
      flexGrow: 1,
      alignItems: 'stretch',
      flexWrap: 'nowrap',
    },
    currentFolderContainer: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    backFolderButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(-0.5),
    },
    currentFolderTitle: {},
    gridItemContent: {
      padding: theme.spacing(1),
      maxHeight: '100%',
    },
    gridItemPaper: {
      height: '100%',
    },
    tablePaper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flexGrow: 1,
    },
    tableHeaderToolbar: {},
    tableHeaderToolbarGutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    newTableRow: {
      animation: `$highlightNewRow 4000ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes highlightNewRow': {
      '0%': {
        background: fade(theme.palette.warning.light, 0.4),
      },
      '100%': {
        background: 'inherit',
      },
    },
    gridItemEmptySelection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
