import immer from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import deepClone from 'lodash-es/cloneDeep';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import { EventPlaylistDto } from '@models';

import AddressingService from '../addressingService';
import * as Actions from './actions';
import {
  ActionTypes,
  AddressingRulesState,
  AddressingState,
  AddressingStructureState,
  AddressingViewModes
} from './types';
import { applyDeniesOnTree, findNode, searchTree } from './utils';

type AddressingActions = ActionType<typeof Actions>;

const initialAddressingRulesState: AddressingRulesState = {
  originalData: [],
  rawData: [],
  processedData: {
    bucketGroups: {},
    bucketSites: {},
    bucketPlayers: {},
    bucketStream: {},
    bucketWorkgroups: {},
  },
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingStructureState: AddressingStructureState = {
  originalData: [],
  rawData: [],
  processedData: [],
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingState: AddressingState = {
  addressingStructure: initialAddressingStructureState,
  addressingRules: initialAddressingRulesState,
  needToUpdateTree: false,
  workgroupId: 0,
  channelId: 0,
  mediaId: 0,
  viewMode: AddressingViewModes.AZ,
  subscribedToWebWorker: false,
  initializationComplete: false,
};

export const addressingReducer: Reducer<AddressingState, AddressingActions> = (
  state = initialAddressingState,
  action
) => {
  return immer(state, (draftState) => {
    switch (action.type) {
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST:
        draftState.addressingStructure.fetchStatus.isFetching = true;
        draftState.addressingStructure.fetchStatus.complete = false;
        draftState.addressingStructure.fetchStatus.error = '';
        draftState.workgroupId = action.payload.workgroupId;
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS:
        draftState.addressingStructure.rawData = deepClone(action.payload.addressingStructure);
        draftState.addressingStructure.fetchStatus.isFetching = false;
        draftState.addressingStructure.fetchStatus.complete = true;
        draftState.addressingStructure.fetchStatus.error = '';
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR:
        draftState.addressingStructure.originalData = [];
        draftState.addressingStructure.rawData = [];
        draftState.addressingStructure.fetchStatus.complete = false;
        draftState.addressingStructure.fetchStatus.isFetching = false;
        draftState.addressingStructure.fetchStatus.error = action.payload;
        draftState.workgroupId = 0;
        break;
      case ActionTypes.BUILD_ADDRESSING_TREE:
        const trees = AddressingService.init(action.payload.addressingStructure, action.payload.workgroupId);
        draftState.addressingStructure.originalData = deepClone(trees);
        draftState.addressingStructure.processedData = deepClone(trees);
        draftState.addressingStructure.processingStatus.complete = true;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_REQUEST:
        draftState.addressingRules.fetchStatus.isFetching = true;
        draftState.addressingRules.fetchStatus.complete = false;
        draftState.mediaId = action.payload.mediaId;
        draftState.channelId = action.payload.channelId;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS:
        draftState.addressingRules.fetchStatus.isFetching = false;
        draftState.addressingRules.fetchStatus.complete = true;
        draftState.addressingRules.originalData = deepClone(action.payload.addresingRules);
        draftState.addressingRules.rawData = deepClone(action.payload.addresingRules);
        draftState.mediaId = action.payload.mediaId;
        draftState.channelId = action.payload.channelId;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_ERROR:
        draftState.addressingRules.fetchStatus.isFetching = false;
        draftState.addressingRules.fetchStatus.complete = false;
        draftState.addressingRules.fetchStatus.error = action.payload;
        draftState.addressingRules.originalData = [];
        draftState.addressingRules.rawData = [];
        draftState.mediaId = 0;
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_START:
        draftState.addressingRules.processingStatus.isProcessing = true;
        draftState.addressingRules.processingStatus.complete = false;
        draftState.addressingRules.rawData = deepClone(action.payload.addressingRules);
        const rulesToProcess = deepClone(action.payload.addressingRules.filter((md) => md.deny !== null));
        AddressingService.applyRules(rulesToProcess, { id: action.payload.mediaId, name: '' });
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_END:
        draftState.addressingRules.processingStatus.isProcessing = false;
        draftState.addressingRules.processingStatus.complete = true;
        draftState.needToUpdateTree = true;
        draftState.addressingRules.processedData = deepClone(action.payload);
        break;
      case ActionTypes.UPDATE_ADDRESSING_TREE:
        applyDeniesOnTree(draftState.addressingStructure.processedData, draftState.addressingRules.processedData);
        draftState.needToUpdateTree = false;
        draftState.initializationComplete = true;
        break;
      case ActionTypes.TOGGLE_NODE:
        draftState.addressingStructure.processedData.forEach((tree) => {
          const node = searchTree(tree, action.payload);
          if (node) {
            node.isExpanded = !node.isExpanded;
            console.info(`${node.$$hashKey}: ${node.isExpanded ? 'expanded' : 'collapsed'}`);
          }
        });
        draftState.needToUpdateTree = false;
        break;
      case ActionTypes.SUBSCRIBE_TO_WEB_WORKER:
        if (!draftState.subscribedToWebWorker) {
          draftState.subscribedToWebWorker = true;
        }
        break;
      case ActionTypes.CLEAR_ADDRESSING_STRUCTURE:
        draftState.addressingStructure = deepClone(initialAddressingStructureState);
        draftState.channelId = 0;
        draftState.workgroupId = 0;
        draftState.initializationComplete = false;
        break;
      case ActionTypes.CLEAR_ADDRESSING_RULES:
        draftState.addressingRules = deepClone(initialAddressingRulesState);
        draftState.addressingStructure.processedData = deepClone(draftState.addressingStructure.originalData);
        draftState.mediaId = 0;
        draftState.initializationComplete = false;
        break;
      case ActionTypes.CLEAR_ADDRESSING_FULL:
        const newState = deepClone(initialAddressingState);
        newState.subscribedToWebWorker = true;
        return newState;
        break;
      case ActionTypes.SET_ADDRESSING_VIEW_MODE:
        draftState.viewMode = action.payload;
        break;
      case ActionTypes.SAVE_ADDRESSING_RULES: 
        const newOriginalData = draftState.addressingRules.rawData.filter((md) => md.deny !== null);
        draftState.addressingRules.originalData = deepClone(newOriginalData);
        break;
      default:
        return state;
    }
  });
};
