import { fade } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsContainer: {
      marginTop: theme.spacing(4),
      background: fade(theme.palette.text.primary, 0.1)
    }
  })
);
