import deepClone from 'lodash-es/cloneDeep';
import i18next from 'i18next';

import { AddressingStructure, AddressingStructureResponse } from './duck';

export default class AddressingHelper {
  private viewModeCollection: AddressingStructure[][] = [];
  private viewModeRules: AddressingStructure[] = [];
  private workgroupResult: AddressingStructureResponse[] = [];
  private bucketWorkgroups: AddressingStructure[] = [];
  private bucketGroups: AddressingStructure[] = [];
  private bucketStream: AddressingStructure[] = [];
  private bucketPlayers: AddressingStructure[] = [];
  private bucketSites: AddressingStructure[] = [];
  private bucketSitesById: AddressingStructure[][] = [];
  private bucketPlayersById: AddressingStructure[][] = [];
  private dataPlayers: AddressingStructureResponse[] = [];
  private sitesResult: AddressingStructureResponse[] = [];
  private streamsResult: AddressingStructureResponse[] = [];
  private allData: AddressingStructure[] = [];
  private dataSet: AddressingStructureResponse[] = [];
  private allLocationsForWorkgroup: AddressingStructure[] = [];
  private groups: AddressingStructure[] = [];
  private sites: AddressingStructure[] = [];
  private viewModeFlatSites: AddressingStructure[] = [];
  private viewModeWorkgroupsSites: AddressingStructure[] = [];
  private bucketViewAll: AddressingStructure[] = [];

  private static instance: AddressingHelper;

  static getInstance() {
    if (!AddressingHelper.instance) {
      AddressingHelper.instance = new AddressingHelper();
    }

    return AddressingHelper.instance;
  }

  private constructor() {}

  public buildTreeView = (structure: AddressingStructureResponse[], currentWorkgroupID: number) => {
    this.viewModeCollection = [];
    this.viewModeRules = [];

    let result = structure;
    this.workgroupResult = result.filter((x) => x.entityType === 2);
    this.bucketGroups = [];
    this.bucketStream = [];
    this.bucketPlayers = [];
    this.bucketSites = [];
    this.bucketSitesById = [];
    this.bucketPlayersById = [];
    this.allData = [];
    this.dataSet = [];
    this.allLocationsForWorkgroup = [];
    // bucket init

    this.dataPlayers = result.filter((x) => x.entityType === 5);
    this.sitesResult = result.filter((x) => x.entityType === 99);
    this.streamsResult = result.filter((x) => x.entityType === 209);
    this.dataSet = result;

    this.GetGroupsFromAboveAndAddThemToBuckets(result, currentWorkgroupID.toString());
    this.GetSitesFromGroupsAboveAndAddThemToBuckets(result, currentWorkgroupID.toString());
    this.allData = this.buildAllData(result, currentWorkgroupID.toString());
    this.allLocationsForWorkgroup = this.buildAllLocationsForWorkgroup(result, currentWorkgroupID.toString());
    this.groups = this.buildGroups(result, currentWorkgroupID.toString());
    this.sites = this.getSitesOfEntity(result, currentWorkgroupID.toString(), 0, 'root');

    //TODO: Optimization here so we don't calculate the tree

    let data = this.allData.concat(this.groups);
    this.bucketViewAll = this.allLocationsForWorkgroup.concat(this.groups);

    this.viewModeFlatSites = this.sites;

    let a: any[] = [];
    this.createWorkgroupView(this.workgroupResult, '-1', 0);
    a.push(this.bucketWorkgroups.filter((wk) => wk.cleanId.toString() === currentWorkgroupID.toString())[0]);
    this.viewModeWorkgroupsSites = a;
    //Sites Linked to channel Adrian C

    let unlinked: AddressingStructure[] = [];
    let linked: AddressingStructure[] = [];

    this.allData.forEach((alg: AddressingStructure) => {
      alg.children.forEach((site: AddressingStructure) => {
        if (site.linked) {
          if (alg.cleanParentId !== currentWorkgroupID.toString()) {
            site.metadata = alg.label.replace('addressingAllLocationsFor', '');
          }
          linked.push(site);
        } else {
          if (alg.cleanParentId !== currentWorkgroupID.toString()) {
            site.metadata = alg.label.replace('addressingAllLocationsFor', '');
          }
          unlinked.push(site);
        }
      });
    });

    //"Sites linked to channel"
    unlinked = unlinked.sort((a: any, b: any) => a.label.localeCompare(b.label));
    linked = linked.sort((a: any, b: any) => a.label.localeCompare(b.label));

    /*** START: ADDED A NEW VIEW MODE STARTING FROM LINKED SITES STRUCTURE ***/

    /**
     * Web Worker does not need to know about cloned items
     * because we sync the hashes in AddressingUtilsV2
     * (applyDeniesOnTree and setValueForChildren)
     *
     * If you need to build a new view mode make sure to deep clone
     * the objects and add a new hash prefix on the clones (eg. 'clone-' or 'cloneV2-')
     */

    let linkedClone = deepClone(linked);

    linkedClone.forEach((site) => {
      site.$$hashKey = `clone-${site.$$hashKey}`;
      this.bucketSites.push(site);

      site.children.forEach((hardware) => {
        hardware.$$hashKey = `clone-${hardware.$$hashKey}`;
        this.bucketPlayers.push(hardware);

        hardware.children.forEach((stream) => {
          stream.$$hashKey = `clone-${stream.$$hashKey}`;
          this.bucketStream.push(stream);
        });
      });
    });

    linkedClone.forEach((site) => {
      site.children.forEach((hardware) => {
        hardware.children = hardware.children.filter((stream) => stream.linked);
      });
    });

    linkedClone.forEach((site) => {
      site.children = site.children.filter((hardware) => hardware.children.length > 0);
    });

    linkedClone = linkedClone.filter((site) => site.children.length > 0);

    /*** END: ADDED A NEW VIEW MODE STARTING FROM LINKED SITES STRUCTURE ***/

    let translationSitesLinkedToChannel = i18next.t('addressing.linkedToChannel');
    let translationOtherSites = i18next.t('addressing.otherSites');
    let lnkFolder: any = this.createWorkgroupFolder(translationSitesLinkedToChannel, '0');
    let unlnkFolder: any = this.createWorkgroupFolder(translationOtherSites, '0');

    lnkFolder[0].children.push(...linkedClone);

    this.viewModeFlatSites = [...linkedClone];

    this.viewModeCollection.push(this.viewModeFlatSites);
    this.viewModeCollection.push(this.viewModeWorkgroupsSites);
    this.viewModeCollection.push(this.bucketViewAll);
    this.viewModeCollection.push(this.viewModeRules);

    return this.viewModeCollection;
  };

  private buildAllData = (dataset: AddressingStructureResponse[], parent: string): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 210)
      .map<any>((s) => ({
        level: 0,
        isExpanded: false,
        isSelected: false,
        label: 'All locations for ' + s.name,
        childCount: 0, //s.players.length,
        modelId: 'G' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'SiteGroup',
        $$hashKey: `SiteGroup${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.getGroupSitesOfEntity(this.sitesResult, s.id),
        sites: [],
        cleanId: s.id,
        cleanParentId: s.parent,
        chckState: 0,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 3,
        ruleId: 0,
      }));
    this.bucketGroups.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private GetGroupsFromAboveAndAddThemToBuckets = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level?: number,
    prefix?: string
  ) => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 3399)
      .map<any>((s) => ({
        level: level !== undefined ? level : s.level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: prefix ? prefix + 'GFA' + s.id : 'GFA' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'GroupFromAbove',
        $$hashKey: `GroupFromAbove${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: [],
        sites: [],
        cleanId: s.id,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 3,
        ruleId: 0,
      }));

    this.bucketGroups.push(...result);
  };

  private GetSitesFromGroupsAboveAndAddThemToBuckets = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level?: number,
    prefix?: string
  ) => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 399)
      .map<any>((s) => ({
        level: level !== undefined ? level : s.level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: prefix ? prefix + 'SFA' + s.id : 'SFA' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'SiteFromGroupFromAbove',
        $$hashKey: `SiteFromGroupFromAbove${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: [],
        sites: [],
        cleanId: s.id,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 4,
        ruleId: 0,
      }));

    result.forEach((element) => {
      if (!this.bucketSitesById[element.cleanId]) {
        this.bucketSitesById[element.cleanId] = [];
      }
      this.bucketSitesById[element.cleanId].push(element);
    });

    this.bucketSites.push(...result);
  };

  private buildAllLocationsForWorkgroup = (
    dataset: AddressingStructureResponse[],
    parent: string
  ): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 210 && f.parent === parent)
      .map<any>((s) => ({
        level: 0,
        isExpanded: false,
        isSelected: false,
        label: 'All locations for ' + s.name,
        childCount: 0, //s.players.length,
        modelId: 'G' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'SiteGroup',
        $$hashKey: `SiteGroup${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.getGroupSitesOfEntity(this.sitesResult, s.id),
        sites: [],
        cleanId: s.id,
        cleanParentId: s.parent,
        chckState: 0,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 3,
        ruleId: 0,
      }));
    this.bucketGroups.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private buildGroups = (dataset: AddressingStructureResponse[], parent: string): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 88)
      .map<any>((s) => ({
        level: 0,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: 'G' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'SiteGroup',
        $$hashKey: `SiteGroup${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.getGroupSitesOfEntity(dataset, s.id),
        sites: [],
        chckState: 0,
        cleanId: s.id,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 3,
        ruleId: 0,
      }));
    this.bucketGroups.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private getGroupSitesOfEntity = (dataset: AddressingStructureResponse[], parent: string): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = this.sitesResult
      .filter((f) => f.parent === parent)
      .map<any>((s) => ({
        level: 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0,
        modelId: 'S' + s.id + '_G' + s.parent,
        parentModelId: 'G' + parent,
        modelType: 'Site',
        $$hashKey: `Site${s.id}Group${s.parent}`,
        hasChildren: true,
        filteredIn: true,
        children: this.getPlayersOfEntity(this.dataSet, s.id, 1, 'S' + s.id + '_G' + s.parent),
        sites: [],
        chckState: 0,
        cleanId: s.id,
        linked: s.link === true ? s.link : false,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 4,
        ruleId: 0,
      }));

    result.forEach((element: any) => {
      if (!this.bucketSitesById[element.cleanId]) {
        this.bucketSitesById[element.cleanId] = [];
      }
      this.bucketSitesById[element.cleanId].push(element);
    });

    this.bucketSites.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private getPlayersOfEntity = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level: number,
    prefix?: string
  ): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = this.dataPlayers
      .filter((f) => f.parent === parent)
      .map<any>((s) => ({
        level: level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: prefix ? prefix + 'P' + s.id : 'P' + s.id,
        parentModelId: prefix ? prefix : 'S' + s.parent,
        modelType: 'Player',
        $$hashKey: `Player${s.id}-${prefix}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.getStreamsOfEntity(this.streamsResult, s.id, level + 1, prefix + 'P' + s.id),
        sites: [],
        chckState: 0,
        cleanId: s.id,
        linked: s.link,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: s.entityType,
        ruleId: 0,
      }));

    result.forEach((element: any) => {
      if (!this.bucketPlayersById[element.cleanId]) {
        this.bucketPlayersById[element.cleanId] = [];
      }
      this.bucketPlayersById[element.cleanId].push(element);
    });

    this.bucketPlayers.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private getSitesOfEntity = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level: number,
    prefix?: string
  ): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 3 && f.parent === parent)
      .map<any>((s) => ({
        level: level !== undefined ? level : s.level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: prefix ? prefix + 'S' + s.id : 'S' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'Site',
        $$hashKey: `Site${s.id}`,
        linked: s.link === true ? s.link : false,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.getPlayersOfEntity(dataset, s.id, level, prefix ? prefix + 'S' + s.id : '').sort((a, b) =>
          a.label.localeCompare(b.label)
        ),
        sites: [],
        cleanId: s.id,
        chckState: 0,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 4,
        ruleId: 0,
      }));

    this.bucketSites.push(...result);

    result.forEach((element: any) => {
      if (!this.bucketSitesById[element.cleanId]) {
        this.bucketSitesById[element.cleanId] = [];
      }
      this.bucketSitesById[element.cleanId].push(element);
    });

    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private getStreamsOfEntity = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level: number,
    prefix?: string
  ): AddressingStructure[] => {
    var result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.entityType === 209 && f.parent === parent)
      .map<any>((s) => ({
        level: level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: prefix ? prefix + 'ST' + s.id + s.parent : 'ST' + s.id,
        parentModelId: prefix ? prefix : 'P' + s.parent,
        modelType: 'Stream',
        $$hashKey: `Stream${s.id}-${prefix}`,
        hasChildren: true, //s.players.length !== 0,
        linked: s.link,
        filteredIn: true,
        children: [],
        sites: [],
        chckState: 0,
        cleanId: s.id,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 6,
        ruleId: 0,
      }));

    this.bucketStream.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private createWorkgroupView = (
    dataset: AddressingStructureResponse[],
    parent: string,
    level: number
  ): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = dataset
      .filter((f) => f.parent === parent)
      .map<any>((s) => ({
        level: s.level + 1,
        isExpanded: false,
        isSelected: false,
        label: s.name,
        childCount: 0, //s.players.length,
        modelId: 'W' + s.id,
        parentModelId: 'W' + s.parent,
        modelType: 'Workgroup',
        $$hashKey: `W${s.id}`,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: this.buildRefAllLocGroups(s.id, s.level + 1).concat(
          //this.createWorkgroupFolder('Folder for' + s.name, 'W' + s.id).concat(
          this.createWorkgroupView(dataset, s.id, s.level + 1)
        ),
        //.concat(this.allLocationGroups.filter( (aLG:any) => aLG.cleanParentId === s.parent)),
        sites: [],
        cleanId: s.id,
        cleanParentId: s.parent,
        hasAddressingRule: false,
        appliedRules: false,
        entityType: 2,
        chckState: 0,
        ruleId: 0,
      }));

    this.bucketWorkgroups.push(...result);
    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private buildRefAllLocGroups = (parent: string, level: number): AddressingStructure[] => {
    let result: AddressingStructure[] = [];
    result = this.allData.filter((f: any) => f.cleanParentId === parent);

    return result.sort((a, b) => a.label.localeCompare(b.label));
  };

  private createWorkgroupFolder = (folderName: any, parentId: string): AddressingStructure[] => {
    let folder: any = [
      {
        id: '0',
        parent: '0',
        name: 'asdf',
        link: false,
        level: 0,
        isExpanded: false,
        isSelected: false,
        label: folderName,
        childCount: 0, //s.players.length,
        modelId: 'FOLDER' + folderName + parentId,
        parentModelId: parentId,
        modelType: 'FOLDER',
        $$hashKey: folderName + parentId,
        hasChildren: true, //s.players.length !== 0,
        filteredIn: true,
        children: [],
        sites: [],
        cleanId: parentId + folderName,
        cleanParentId: parentId + folderName,
        hasAddressingRule: false,
        appliedRules: true,
        entityType: 2,
        chckState: 0,
        ruleId: 0,
      },
    ];

    return folder;
  };
}
