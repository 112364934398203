import AddressingTree from '../AddressingTree';
import { useDispatch, useSelector } from 'react-redux';

import {
  useAddressingContextChannelId,
  useAddressingContextMediaId,
  useAddressingContextWorkgroupId,
} from '../context/hooks';
import { AddressingViewModes, selectAddressingViewMode, selectUpdatedAddressingRules } from '../duck';
import { setAddressingViewMode } from '../duck/actions';

const useAddressing = () => {
  const contextChannelId = useAddressingContextChannelId();
  const contextMediaId = useAddressingContextMediaId();
  const contextWorkgroupId = useAddressingContextWorkgroupId();
  const viewMode = useSelector(selectAddressingViewMode);
  const dispatch = useDispatch();

  return {
    AddressingTree,
    selectUpdatedAddressingRules,
    channelId: contextChannelId,
    mediaId: contextMediaId,
    workgroupId: contextWorkgroupId,
    viewMode: viewMode,
    setViewMode: (viewMode: AddressingViewModes) => dispatch(setAddressingViewMode(viewMode)),
  };
};

export default useAddressing;
