import { createSelector } from 'reselect';

import { addressingAreaName, AddressingState } from './types';

export const selectAddressingState = (state: any): AddressingState => {
  return state[addressingAreaName];
};

export const selectAddressingStructure = createSelector([selectAddressingState], (state) => state.addressingStructure);
export const selectAddressingRules = createSelector([selectAddressingState], (state) => state.addressingRules);
export const selectNeedToUpdateTree = createSelector([selectAddressingState], (state) => state.needToUpdateTree);
export const selectAddressingViewMode = createSelector([selectAddressingState], (state) => state.viewMode);
export const selectUpdatedAddressingRules = createSelector(
  [selectAddressingState],
  (state) => state.addressingRules.rawData
);
export const selectInitializationComplete = createSelector(
  [selectAddressingState],
  (state) => state.initializationComplete
);
export const selectAddressingMediaId = createSelector([selectAddressingState], (state) => state.mediaId);