import AddressingWorker from "worker-loader!./addressingWorker"; // eslint-disable-line import/no-webpack-loader-syntax
import AddressingHelper from "./addressingHelper"; // eslint-disable-line import/no-webpack-loader-syntax
import {
  AddressingStructureResponse,
  MediaDeny,
  MediaDenyApplyWorkerResponse,
  MediaDenyApplyResult,
  AddressingStructure,
} from "./duck";
import { IdName } from "react-tools";

const worker = new AddressingWorker();

const publish = (message: any) => {
  worker.postMessage(message);
};

const subscribe = (
  handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void
) => {
  worker.addEventListener("message", handler);
};

const unsubscribe = (
  handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void
) => {
  worker.removeEventListener("message", handler);
};

const INIT_ADDRESSING_WORKER = "request_buckets_init";
const PROCESS_ADDRESSING_RULES = "request_apply_addressing_rules";
const PROCESS_ADDRESSING_RULES_COMPLETE = "response_apply_addressing_rules";

interface AddressingService {
  init: (data: AddressingStructureResponse[], currentWorkgroupId: number) => AddressingStructure[][];
  applyRules: (rules: MediaDeny[], media: IdName) => void;
  subscribe: (callback: (result: MediaDenyApplyResult) => void) => void;
  unsubscribe: (handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void) => void;
}

const AddressingService: AddressingService = {
  init: (data: AddressingStructureResponse[], currentWorkgroupId: number) => {
    publish({
      message: INIT_ADDRESSING_WORKER,
      data: {
        result: data,
        currentWorkgroupID: currentWorkgroupId,
      },
    });

    return AddressingHelper.getInstance().buildTreeView(data, currentWorkgroupId);
  },

  applyRules: (rules: MediaDeny[], media: IdName) => {
    publish({
      message: PROCESS_ADDRESSING_RULES,
      requestId: +new Date(),
      mediaName: media.name,
      data: {
        denyRules: rules,
      },
    });
  },

  subscribe: (callback: (result: MediaDenyApplyResult) => void) => {
    console.info("Subscribed to WebWorker");
    subscribe((response: { data: MediaDenyApplyWorkerResponse }) => {
      if (response.data.message === PROCESS_ADDRESSING_RULES_COMPLETE) {
        return callback(response.data.data);
      }
    });
  },

  unsubscribe: (handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void) => {
    unsubscribe(handler);
  }
};

export default AddressingService;
