import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
    },
    sortableListItem: {
      listStyle: 'none',
      color: theme.palette.text.primary,
    },
  })
);
