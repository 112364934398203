import { action } from 'typesafe-actions';

import { MediaUpdateDto, MessageType } from '@models';
import { MediaDto } from '@models/MediaDto';

import { ActionTypes, FolderPathItem, MessageLibraryItem } from './types';

export const addMessages = (messageType: MessageType, medias: MediaDto[]) =>
  action(ActionTypes.ADD_MESSAGES, { messageType, medias });

export const pushFolder = (folder: MessageLibraryItem) => action(ActionTypes.PUSH_FOLDER, { folder });
export const popFolder = (folderPathItem?: FolderPathItem) => action(ActionTypes.POP_FOLDER, { folderPathItem });
export const resetRootFolder = (messageType: MessageType) => action(ActionTypes.RESET_ROOT_FOLDER, { messageType });

export const fetchLibraryRequest = (workgroupId: number) => action(ActionTypes.FETCH_LIBRARY_REQUEST, { workgroupId });

export const fetchLibrarySuccess = (workgroupId: number, messages: MessageLibraryItem[], messageType: MessageType) =>
  action(ActionTypes.FETCH_LIBRARY_SUCCESS, {
    workgroupId,
    messages,
    messageType,
  });

export const fetchMessageInfoRequest = (id: number) => action(ActionTypes.FETCH_MESSAGE_INFO_REQUEST, { id });

export const fetchMessageInfoSuccess = (message: MediaDto) =>
  action(ActionTypes.FETCH_MESSAGE_INFO_SUCCESS, { message });

export const deleteMessagesRequest = (mediaIds: number[]) => action(ActionTypes.DELETE_MESSAGES_REQUEST, { mediaIds });

export const deleteMessagesSuccess = (mediaIds: number[]) => action(ActionTypes.DELETE_MESSAGES_SUCCESS, { mediaIds });

export const saveMessagesRequest = (message: MediaUpdateDto) =>
  action(ActionTypes.SAVE_MESSAGE_INFO_REQUEST, { message });

export const saveMessagesSuccess = (message: MediaUpdateDto) =>
  action(ActionTypes.SAVE_MESSAGE_INFO_SUCCESS, { message });
