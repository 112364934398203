import { Divider, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import arrayMove from 'array-move';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SortEndHandler } from 'react-sortable-hoc';
import { ZoneSelectors } from 'zone/duck';

import { ListMediaItem, MessageCountMap } from '../types';
import { BrowseDialog } from './BrowseDialog';
import { processBrowsedMedias, generateListMediaItemCopies } from './browsedMediaProcessor';
import { useStyles } from './PlaylistMessages.jss';
import { PlaylistMessagesToolbar } from './PlaylistMessagesToolbar';
import { SortableListContainer } from './SortableList';

export interface PlaylistMessagesProps {
  messages: ListMediaItem[];
  onMessagesChange: (messages: ListMediaItem[]) => void;
  playlistId: number;
}

const MessagesSkeleton: React.FunctionComponent = React.memo(() => {
  return (
    <div>
      <div>
        <Skeleton variant="text" width={120} height={24} />
        <Skeleton variant="text" width={250} height={24} />
        <Skeleton variant="text" width={20} height={24} />
        <Divider />
      </div>
      <div>
        <Skeleton variant="text" width={180} height={24} />
        <Skeleton variant="text" width={190} height={24} />
        <Skeleton variant="text" width={70} height={24} />

        <Divider />
      </div>
      <div>
        <Skeleton variant="text" width={90} height={24} />
        <Skeleton variant="text" width={150} height={24} />
        <Skeleton variant="text" width={50} height={24} />
        <Divider />
      </div>
    </div>
  );
});

export const PlaylistMessages: React.FunctionComponent<PlaylistMessagesProps> = (props) => {
  const { messages, onMessagesChange } = props;
  const [browseMode, setBrowseMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const classes = useStyles();

  useEffect(() => {
    setSelectedIds([]);
  }, [props.playlistId]);

  const onItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = Number(event.currentTarget.getAttribute('data-id'));
      if (id) {
        const currentIndex = selectedIds.indexOf(id);
        const newSelected = [...selectedIds];

        if (currentIndex === -1) {
          newSelected.push(id);
        } else {
          newSelected.splice(currentIndex, 1);
        }

        setSelectedIds(newSelected);
      }
    },
    [selectedIds, setSelectedIds]
  );

  const onSortEnd = useCallback<SortEndHandler>(
    (sort, sortEvent) => {
      onMessagesChange(arrayMove(messages, sort.oldIndex, sort.newIndex));
    },
    [messages]
  );

  const onBrowseClick = useCallback(() => {
    setBrowseMode(true);
  }, [setBrowseMode]);

  const onBrowseDone = useCallback(
    (newMessagesCount: MessageCountMap) => {
      const newMessages = processBrowsedMedias(messages, newMessagesCount);
      onMessagesChange(newMessages);
      setBrowseMode(false);
    },
    [messages, onMessagesChange, setBrowseMode]
  );

  const onDuplicateClick = useCallback(() => {
    let newMessages = [...messages];

    selectedIds.sort().forEach((e: number, index: number) => {
      const messageIndex = newMessages.findIndex((f) => f.localId === e);
      if (index === -1) {
        return;
      }

      const [copied] = generateListMediaItemCopies(newMessages, newMessages[messageIndex].id, 1);
      newMessages.splice(messageIndex + 1, 0, copied);
    });

    newMessages.forEach((e, index) => ({
      ...e,
      localId: index + 1,
    }));
    setSelectedIds([]);
    props.onMessagesChange(newMessages);
  }, [selectedIds, messages, props.onMessagesChange]);

  const onRemoveClick = useCallback(() => {
    const newMessages = messages.filter((m) => selectedIds.indexOf(m.localId) === -1);
    onMessagesChange(newMessages);
    setSelectedIds([]);
  }, [selectedIds, messages]);

  const loading = useSelector(ZoneSelectors.selectIsFetchingPlaylist);
  const [t] = useTranslation();

  return (
    <>
      <div className={classes.container}>
        <PlaylistMessagesToolbar onBrowse={onBrowseClick} onDuplicate={onDuplicateClick} onRemove={onRemoveClick} />
        {loading && <MessagesSkeleton />}
        {!loading && messages.length > 0 && (
          <SortableListContainer
            selectedIds={selectedIds}
            onClick={onItemClick}
            items={messages}
            onSortEnd={onSortEnd}
            useDragHandle={true}
            lockAxis="y"
            helperClass={classes.sortableListItem}
          />
        )}
        {!loading && messages.length === 0 && (
          <Typography variant="subtitle2" color="textSecondary" align="center">
            {t('playlistMessages.emptyList')}
          </Typography>
        )}
      </div>
      <BrowseDialog open={browseMode} onCancel={() => setBrowseMode(false)} onDone={onBrowseDone} />
    </>
  );
};
