import {
  BottomSaveCancel,
  FormMediaDescriptionListItem,
  FormMediaNameListItem,
  SchedulingListItems
} from 'components';
import { FormMediaDurationListItem } from 'components/mediaEdit/FormMediaDurationListItem';
import { FormMediaVolumeListItem } from 'components/mediaEdit/FormMediaVolumeListItem';
import { DirtyActions } from 'dirty';
import { Form, useFormikContext } from 'formik';
import { LibrarySelectors, MessageLibraryItem } from 'library';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { List, ListItem, ListItemText } from '@material-ui/core';

import { useStyles } from './MessageEdit.jss';
import { MessagePreview } from './MessagePreview';

export interface MessageEditFormProps {
    message?: MessageLibraryItem,
}

export const MessageEditForm = (props: MessageEditFormProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const formik = useFormikContext();
    const savingMessage = useSelector(LibrarySelectors.selectSavingMessage);

    useEffect(() => {
        const dirty = formik.dirty;
        dispatch(DirtyActions.setDirty(dirty));
    }, [formik.dirty]);

    const onCancel = useCallback(() => {
        formik.setValues({
            ...(formik.initialValues as any)
        });
        dispatch(DirtyActions.setDirty(false));
    }, [formik]);

    return (
        <Form className={classes.form}>
            <List className={classes.listForm}>
                <FormMediaNameListItem />
                <FormMediaDurationListItem trueDuration={props.message?.mediaInfo.properties?.trueDuration} />
                <ListItem>
                <ListItemText inset disableTypography>
                    <MessagePreview
                    variant="large"
                    contentFilePath={props.message?.mediaInfo.contentFilePath}
                    contentId={props.message?.mediaInfo.contentId}
                    />
                </ListItemText>
                </ListItem>
                <FormMediaVolumeListItem />
                <SchedulingListItems />
                <FormMediaDescriptionListItem />
            </List>
            <BottomSaveCancel saveInProgres={savingMessage} onCancel={onCancel} />
        </Form>
    );
};