import { FormListCollapseItem } from 'components';
import {
  endOfDay,
  format,
  isValid,
  setMilliseconds,
  startOfDay
} from 'date-fns';
import { useField } from 'formik';
import {
  getDateFormatBasedOnBrowser,
  getTimeFormatBasedOnBrowser
} from 'localization/dateLocale';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEndOfDay, isStartOfDay } from 'utils/dateTimeUtils';

import {
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from '@material-ui/icons/Schedule';

import {
  DiffusionDaysButtons,
  DiffusionDaysLabels
} from './DiffusionDaysButtons';
import { MediaScheduleDates } from './MediaScheduleDates';
import { MediaScheduleTime } from './MediaScheduleTime';
import { useStyles } from './SchedulingListItems.jss';

export interface SchedulingListItemsProps {}

export const SchedulingListItems: React.FunctionComponent<SchedulingListItemsProps> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const [startDate] = useField<Date>('scheduling.startDate');
  const [endDate] = useField<Date>('scheduling.endDate');
  const [startTime, m1, startTimeHelper] = useField<Date>('scheduling.startTime');
  const [endTime, m2, endTimeHelper] = useField<Date>('scheduling.endTime');
  const [isAllDay, setIsAllDay] = useState(true);

  useEffect(() => {
    if ((startTime.value && endTime.value && !isStartOfDay(startTime.value)) || !isEndOfDay(endTime.value)) {
      setIsAllDay(false);
    }
  }, [startTime, endTime]);

  const toggleIsAllDay = useCallback(
    (checked: boolean) => {
      if (checked) {
        startTimeHelper.setValue(startOfDay(new Date()));

        // Use miliseconds to be ok with initial value.
        endTimeHelper.setValue(setMilliseconds(endOfDay(new Date()), 0));
      }

      setIsAllDay(checked);
    },
    [startTimeHelper]
  );

  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

  return (
    <>
      <FormListCollapseItem
        labelKey="dateInterval"
        icon={<DateRangeIcon />}
        value={
          <>
            <Typography component="span" variant="body1" color="textPrimary">
              {isValid(startDate.value)
                ? format(startDate.value, getDateFormatBasedOnBrowser())
                : t('media.date.notSet')}
            </Typography>
            <ArrowRightAlt className={classes.rangeArrow} />
            <Typography component="span" variant="body1" color="textPrimary">
              {isValid(endDate.value) ? format(endDate.value, getDateFormatBasedOnBrowser()) : t('media.date.notSet')}
            </Typography>
            <div>
              <DiffusionDaysLabels />
            </div>
          </>
        }
      >
        <List disablePadding>
          <ListItem>
            <ListItemText
              inset
              primaryTypographyProps={{component: 'div'}}
              primary={
                <div>
                  <MediaScheduleDates />
                  <DiffusionDaysButtons />
                </div>
              }
            />
          </ListItem>
        </List>
      </FormListCollapseItem>
      <FormListCollapseItem
        labelKey="timeInterval"
        icon={<ScheduleIcon />}
        value={
          <>
            <Typography component="span" variant="body1" color="textPrimary">
              {isValid(startTime.value)
                ? format(startTime.value, getTimeFormatBasedOnBrowser())
                : t('media.date.notSet')}
            </Typography>
            <ArrowRightAlt className={classes.rangeArrow} />
            <Typography component="span" variant="body1" color="textPrimary">
              {isValid(endTime.value) ? format(endTime.value, getTimeFormatBasedOnBrowser()) : t('media.date.notSet')}
            </Typography>
          </>
        }
      >
        <List component="div" disablePadding>
          <ListItem>
            <ListItemText inset>
              <FormControlLabel
                className={classes.checkbox}
                control={<Checkbox color="secondary" />}
                label={t('isAllDay')}
                checked={isAllDay}
                onChange={(_, checked: boolean) => toggleIsAllDay(checked)}
              />
            </ListItemText>
          </ListItem>
          {!isAllDay && (
            <ListItem>
              <ListItemText inset primary={<MediaScheduleTime />} />
            </ListItem>
          )}
        </List>
      </FormListCollapseItem>
    </>
  );
};
