import { MediaForms } from 'components';
import { MediaFormData } from 'components/mediaEdit/forms';
import { format, formatISO } from 'date-fns';
import { Formik, FormikProps, FormikValues } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { MediaUpdateDto } from '@models';

import { LibrarySelectors, LibraryThunks } from '../duck';
import { MessageEditForm } from './MessageEditForm';

export interface MessageEditProps {
  id?: number;
}

export const MessageEdit = React.forwardRef<FormikProps<any>, MessageEditProps>((props, _) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { id: routeId } = useParams<{ id: string }>();
  let { id: propsId } = props;
  let messageId = Number(propsId);
  if (!messageId) {
    if (!routeId) {
      history.push('/library');
    } else {
      messageId = Number(routeId);
    }
  }

  const message = useSelector((state) => LibrarySelectors.selectMessage(state, messageId));

  useEffect(() => {
    dispatch(LibraryThunks.fetchMessage(messageId));
  }, [messageId]);

  const onSave = useCallback(
    (values: MediaFormData) => {
      if (!message) {
        return;
      }

      const updateDto: MediaUpdateDto = {
        id: message.mediaInfo.id,
        name: values.name,
        description: values.description,
        startDate: format(values.scheduling.startDate, 'yyyy-MM-dd 00:00:00'),
        endDate: format(values.scheduling.endDate, 'yyyy-MM-dd 00:00:00'),
        startTime: format(values.scheduling.startTime, 'HH:mm:ss'),
        endTime: format(values.scheduling.endTime, 'HH:mm:ss'),
        timezone: message.mediaInfo.timezone,
        diffusionDays: values.scheduling.diffusionDays,
        endMedia: message.mediaInfo.endMedia,
        mediaFolderId: message.mediaInfo.mediaFolderId,
        mediaType: message.mediaInfo.mediaType,
        properties: {
          ...message.mediaInfo.properties,
          soundVolume: values.soundVolume,
        } as any,
        duration: values.duration,
      };
      dispatch(LibraryThunks.saveMessage(updateDto));
    },
    [message]
  );

  const memoFormik = useMemo(() => {
    return(
      <Formik
        initialValues={MediaForms.mediaDtoToFormData(message?.mediaInfo)}
        validationSchema={MediaForms.mediaFormSchema}
        enableReinitialize
        onSubmit={onSave}
      >
        <MessageEditForm message={message} />
      </Formik>
  )}, [onSave, message]);

  return memoFormik;
});
