import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    playlistContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
      flex: '1 1 0',
    },
    tabs: {},
    tabPanel: {
      flexGrow: 1,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    nameField: {
      fontFamily: theme.typography.h5.fontFamily,
      fontSize: theme.typography.h5.fontSize,
      marginTop: theme.spacing(1),
    },
    playlistForm: {
      flexGrow: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    playlistFormContent: {
      minHeight: 0,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
    },
    loadingContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  })
);
