import { useField, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import VolumeUp from '@material-ui/icons/VolumeUp';

import { useStyles } from './FormMediaVolumeListItem.jss';

export const FormMediaVolumeListItem: React.FunctionComponent = (props) => {
  const [field, meta, helpers] = useField('soundVolume');
  const classes = useStyles();
  const [t] = useTranslation();
  const [value, setValue] = React.useState(field.value);
  const onChangeCommited = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      helpers.setValue(value as number);
    },
    [helpers]
  );
  const innerOnChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setValue(value as number);
    },
    [setValue]
  );

  useEffect(() => {
    if (field.value !== undefined) {
      setValue(field.value);
    } else {
      setValue(0);
    }
  }, [field.value]);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <VolumeUp />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="caption" color="textSecondary">
              {t('media.soundVolume')}
            </Typography>
          }
          secondary={
            <Typography color="textPrimary" variant="body1">
              {`${field.value}%`}
            </Typography>
          }
        ></ListItemText>
      </ListItem>
      <ListItem className={classes.sliderItem}>
        <ListItemText inset>
          <Slider
            className={classes.slider}
            value={value}
            onChange={innerOnChange}
            onChangeCommitted={onChangeCommited}
          />
        </ListItemText>
      </ListItem>
    </>
  );
};
