import CryptoES from 'crypto-es';
import HttpService from 'utils/http';

import { MediaDto } from '@models/MediaDto';

import { FileAssembleInfo, FileUploadRequest, UploadMediaInfo } from './types';

export const computeSha256 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    try {
      var reader = new FileReader();
      var CHUNK_SIZE = 1 * 1024 * 1024;
      var offset = 0;

      var sha256 = CryptoES.algo.SHA256.create();
      var nextChunk: Function;
      if (reader.readAsBinaryString !== undefined) {
        nextChunk = (x: any) => {
          reader.readAsBinaryString(x);
        };
      } else {
        nextChunk = (x: any) => {
          reader.readAsArrayBuffer(x);
        };
      }

      reader.onload = function () {
        if (reader.readAsBinaryString === undefined) {
          var word = CryptoES.lib.WordArray.create(reader.result as any);
          sha256.update(word);
        } else {
          sha256.update(CryptoES.enc.Latin1.parse(reader.result as any));
        }
        if (offset < file.size) {
          offset += CHUNK_SIZE;
          var slice = file.slice(offset, offset + CHUNK_SIZE);
          nextChunk(slice);
        } else {
          var fileSha = sha256.finalize().toString();
          resolve(fileSha);
        }
      };
      nextChunk(file.slice(offset, offset + CHUNK_SIZE));
    } catch (err) {
      reject(err);
    }
  });
};

const getChunkStatus = (fileUploadRequest: FileUploadRequest): Promise<Response> => {
  const url =
    process.env.REACT_APP_ROOT_DOMAIN +
    `v6/upload/?flowChunkNumber=${fileUploadRequest.flowChunkNumber}&flowChunkSize=${fileUploadRequest.flowChunkSize}&flowFilename=${fileUploadRequest.flowFilename}&flowIdentifier=${fileUploadRequest.flowIdentifier}&flowRelativePath=${fileUploadRequest.flowRelativePath}&flowTotalChunks=${fileUploadRequest.flowTotalChunks}&flowTotalSize=${fileUploadRequest.flowTotalSize}&isContent=${fileUploadRequest.isContent}`;
  return fetch(url, { method: 'GET' });
};

const sendChunk = (fileUploadRequest: FileUploadRequest, file: File): Promise<Response> => {
  // read the chunk from file
  const offset = (fileUploadRequest.flowChunkNumber - 1) * fileUploadRequest.flowChunkSize;
  const blob = file.slice(offset, Math.min(offset + fileUploadRequest.flowChunkSize, file.size));

  // build multipart form data
  const formData = Object.keys(fileUploadRequest).reduce<FormData>((data, k) => {
    data.append(k, fileUploadRequest[k as keyof FileUploadRequest].toString());
    return data;
  }, new FormData());
  formData.append('file', blob);

  // send it to the server
  return fetch(process.env.REACT_APP_ROOT_DOMAIN + `v6/upload`, {
    method: 'POST',
    body: formData,
  });
};

export const uploadChunk = (fileUploadRequest: FileUploadRequest, file: File): Promise<Response> => {
  return new Promise((resolve, reject) => {
    getChunkStatus(fileUploadRequest).then((result) => {
      if (result.status === 502) {
        sendChunk(fileUploadRequest, file)
          .then((chunkResult) => resolve(chunkResult))
          .catch((reason) => reject(reason));
      }
      if (result.status === 509) {
        resolve(result);
      }
      if (result.status === 200) {
        resolve(result);
      }
    });
  });
};

export const finishContentUpload = (fileAssembleInfo: FileAssembleInfo): Promise<Response> => {
  return HttpService.get('v6/contentFile', undefined, fileAssembleInfo);
};

export const createMedia = (mediaInfo: UploadMediaInfo) => HttpService.post<MediaDto>('v6/media/upload', mediaInfo);
