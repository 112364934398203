import React, { useCallback } from 'react';
import { EntityType } from 'react-tools';

import { Divider } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './Addressing.jss';
import AddresingNode from './components/AddressingNode';
import { AddressingViewModes } from './duck';
import useAddressing from './hooks/addressing';

const AddressingSkeleton: React.FunctionComponent = React.memo(() => {
  return (
    <div>
      <div>
        <Skeleton variant="text" width={120} height={24} style={{ marginLeft: 40 }} />
        <Divider />
      </div>
      <div style={{ marginLeft: 40 }}>
        <div>
          <Skeleton variant="text" width={120} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={250} height={24} style={{ marginLeft: 120 }} />
          <Skeleton variant="text" width={20} height={24} style={{ marginLeft: 120 }} />
          <Divider />
        </div>
        <div>
          <Skeleton variant="text" width={180} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={190} height={24} style={{ marginLeft: 100 }} />
          <Skeleton variant="text" width={70} height={24} style={{ marginLeft: 140 }} />

          <Divider />
        </div>
        <div>
          <Skeleton variant="text" width={90} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={150} height={24} style={{ marginLeft: 140 }} />
          <Skeleton variant="text" width={50} height={24} style={{ marginLeft: 120 }} />
          <Divider />
        </div>
      </div>
    </div>
  );
});

interface AdressingProps {
  workgroupId?: number;
  channelId?: number;
  mediaId?: number;
}

const Addressing: React.FunctionComponent<AdressingProps> = (props) => {
  const { AddressingTree, channelId, mediaId, viewMode, setViewMode } = useAddressing();
  const classes = useStyles();

  const updateViewMode = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setViewMode(Number.parseInt(e.target.value, 10));
  }, [setViewMode]);

  return (
    <div className={classes.container}>
      <FormControl component="fieldset" className={classes.toolbar}>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={viewMode}
          defaultValue={AddressingViewModes.AZ}
          onChange={updateViewMode}
        >
          <FormControlLabel value={AddressingViewModes.AZ} control={<Radio color="primary" />} label="A-Z" />
          <FormControlLabel value={AddressingViewModes.Groups} control={<Radio color="primary" />} label="Groups" />
        </RadioGroup>
      </FormControl>
      <AddressingTree
        viewMode={viewMode}
        style={{ height: '100%', flex: '1 1 0' }}
        mediaDenies={
          mediaId
            ? undefined
            : [
                {
                  idMediaChannel: channelId,
                  idMedia: mediaId,
                  idEntity: 1,
                  idEntityType: EntityType.Workgroup,
                  deny: true,
                },
              ]
        }
        placeholder={<AddressingSkeleton />}
        nodeRenderer={AddresingNode}
      />
    </div>
  );
};

export default Addressing;
