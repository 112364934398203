import { MediaInfo } from 'library';
import React, { useCallback, useEffect, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { MediaDto } from '@models/MediaDto';

import { computeSha256, createMedia, finishContentUpload, uploadChunk } from './api';
import { FileUploadRequest, FileUploadResult, UploadStatus } from './types';
import { useStyles } from './uploader.jss';

export interface UploadFileProps {
  file: File;
  folderId?: number;
  removeFile: (file: File) => void;
  chunkSize: number;
  startUpload: boolean;
  filesAreContent: boolean;
  onFileComplete?: (result: FileUploadResult) => void;
}

interface UploadFileState {
  chunks: FileUploadRequest;
  status: UploadStatus;
  media: MediaDto | null;
  error: string;
}

export const UploadFile: React.FunctionComponent<UploadFileProps> = React.memo((props) => {
  const classes = useStyles();
  const { file, onFileComplete, startUpload, removeFile } = props;
  const [uploadState, setUploadState] = useState<UploadFileState>({
    chunks: {
      flowFilename: props.file.name,
      flowChunkNumber: 1,
      flowTotalChunks: Math.ceil(props.file.size / props.chunkSize),
      flowChunkSize: props.chunkSize,
      flowIdentifier: '',
      flowRelativePath: props.file.name,
      flowTotalSize: props.file.size,
      isContent: props.filesAreContent,
    },
    status: UploadStatus.NotStarted,
    media: null,
    error: '',
  });

  const onRemoveClick = useCallback(() => {
    removeFile(file);
  }, [file, removeFile]);

  useEffect(() => {
    const fn = async () => {
      switch (uploadState.status) {
        case UploadStatus.NotStarted:
          if (startUpload) {
            setUploadState({
              ...uploadState,
              status: UploadStatus.Analyzing,
            });
          }
          break;
        case UploadStatus.Analyzing: {
          try {
            const fileSha = await computeSha256(file);
            setUploadState({
              ...uploadState,
              chunks: { ...uploadState.chunks, flowIdentifier: fileSha },
              status: UploadStatus.CreateMedia,
            });
          } catch (err) {
            setUploadState({
              ...uploadState,
              error: err,
              status: UploadStatus.Failed,
            });
          }
          break;
        }
        case UploadStatus.CreateMedia: {
          try {
            const mediaDto = await createMedia({
              mediaOrigin: 1,
              mediaFolderId: props.folderId,
              media: {
                fileName: file.name,
                fileShaCode: uploadState.chunks.flowIdentifier,
                mediaId: -1,
                mediaName: file.name,
              },
            });
            setUploadState({
              ...uploadState,
              status: UploadStatus.ChunkUpload,
              media: mediaDto,
            });
          } catch (err) {
            setUploadState({
              ...uploadState,
              error: err,
              status: UploadStatus.Failed,
            });
          }
          break;
        }
        case UploadStatus.ChunkUpload:
          try {
            const response = await uploadChunk(uploadState.chunks, props.file);
            if (response.status === 509) {
              setUploadState({
                ...uploadState,
                status: UploadStatus.Done,
              });
              return;
            }
            const currentChunkNumber = uploadState.chunks.flowChunkNumber + 1;
            const lastChunkUploaded = currentChunkNumber > uploadState.chunks.flowTotalChunks;
            if (lastChunkUploaded) {
              setUploadState({
                ...uploadState,
                status: UploadStatus.AssemblingFile,
              });
            } else {
              setUploadState({
                ...uploadState,
                chunks: {
                  ...uploadState.chunks,
                  flowChunkNumber: currentChunkNumber,
                },
              });
            }
          } catch (err) {
            setUploadState({
              ...uploadState,
              error: err,
              status: UploadStatus.Failed,
            });
          }
          break;
        case UploadStatus.AssemblingFile:
          try {
            await finishContentUpload({
              sha: uploadState.chunks.flowIdentifier,
              size: file.size,
            });
            setUploadState({
              ...uploadState,
              status: UploadStatus.Done,
            });
          } catch (err) {
            setUploadState({
              ...uploadState,
              error: err,
              status: UploadStatus.Failed,
            });
          }
          break;
        case UploadStatus.Done:
          if (onFileComplete) {
            onFileComplete({ success: true, file, media: uploadState.media });
          }
          break;
        case UploadStatus.Failed:
          if (onFileComplete) {
            onFileComplete({
              success: false,
              file,
              media: uploadState.media,
            });
          }
      }
    };
    fn();
  }, [startUpload, uploadState, setUploadState]);

  return (
    <>
      <ListItem>
        <ListItemText primary={props.file.name} />
        <ListItemSecondaryAction>
          {uploadState.status === UploadStatus.NotStarted && (
            <IconButton edge="end" onClick={onRemoveClick}>
              <CancelIcon />
            </IconButton>
          )}
          {uploadState.status === UploadStatus.Done && <CheckCircleIcon className={classes.success} />}
          {uploadState.status === UploadStatus.Failed && <ErrorIcon className={classes.fail} />}
        </ListItemSecondaryAction>
      </ListItem>
      <LinearProgress
        valueBuffer={0}
        variant={uploadState.status === UploadStatus.Analyzing ? 'buffer' : 'determinate'}
        value={
          uploadState.status === UploadStatus.Done
            ? 100
            : uploadState.status !== UploadStatus.ChunkUpload
            ? 0
            : (uploadState.chunks.flowChunkNumber * 100) / uploadState.chunks.flowTotalChunks
        }
      />
    </>
  );
});
