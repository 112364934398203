import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { FileUploadResult } from 'components/upload/types';
import { UploadDialog } from 'components/upload/uploadFileDialog';
import { LayoutSelectors } from 'layout';
import { MessageLibraryItem } from 'library/duck/types';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DataGridContextProvider } from 'react-tools';

import Paper from '@material-ui/core/Paper';
import { MediaDto } from '@models';

import { LibraryActions, LibrarySelectors, LibraryThunks } from '../duck';
import { NavigatorList } from './list/NavigatorList';
import { useStyles } from './Navigator.jss';
import { NavigatorBreadcrumbs } from './NavigatorBreadcrumbs';
import { NavigatorToolbar } from './NavigatorToolbar';
import { NavigatorTable } from './table/NavigatorTable';

export interface NavigatorProps {
  variant: 'table' | 'list';
  secondaryAction?: React.FunctionComponent<{
    item: MessageLibraryItem;
    [key: string]: any;
  }>;
  secondaryActionProps?: {
    [key: string]: any;
  };
  secondaryActionLabel?: string;
  hideControls?: boolean;
  disableSelection?: boolean;
  onSelection?: (id: number) => void;
  selected?: number;
}

export const Navigator: React.FunctionComponent<NavigatorProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const history = useHistory();

  const { selected, variant, onSelection, hideControls, disableSelection } = props;

  const dispatch = useDispatch();
  const [sortKey, setSortKey] = React.useState<'creationDate' | 'name'>('creationDate');
  const selectedMessageType = useSelector(LayoutSelectors.selectLayoutState);
  const libraryItems = useSelector((state) => LibrarySelectors.selectMessages(state, sortKey));
  const currentFolder = useSelector(LibrarySelectors.selectCurrentFolder);
  const fetchingMessages = useSelector(LibrarySelectors.selectFetchingLibrary);

  const [items, setItems] = React.useState<MessageLibraryItem[]>([]);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  const [newlyAdded, setNewlyAdded] = React.useState<number[]>([]);
  const tableContainer = React.useRef<HTMLDivElement>(null);

  const handleUploadClick = useCallback(() => setUploadDialogOpen(!uploadDialogOpen), [
    uploadDialogOpen,
    setUploadDialogOpen,
  ]);
  const handleUploadCancel = useCallback(() => setUploadDialogOpen(false), [setUploadDialogOpen]);

  const handleDeleteClick = useCallback(() => {
    setDeleteDialogOpen(true);
  }, [selected, props.selected, dispatch]);

  const onDelete = useCallback(() => {
    if (selected) {
      dispatch(LibraryThunks.deleteMessages([selected]));
      setDeleteDialogOpen(false);
    }
  }, [deleteDialogOpen, setDeleteDialogOpen, selected]);

  const onCancelDelete = useCallback(() => {
    setDeleteDialogOpen(false);
  }, [deleteDialogOpen, setDeleteDialogOpen]);
  
  const handleSortClick = useCallback(
    (sortKey: 'name' | 'creationDate') => {
      setSortKey(sortKey);
    },
    [setSortKey]
  );

  const onMessageUpload = useCallback(
    (files: FileUploadResult[]) => {
      const medias = files.reduce<MediaDto[]>((a, i) => {
        if (i.media) {
          a.push(i.media);
        }
        return a;
      }, []);
      setNewlyAdded(medias.map((f) => f.id));
      setUploadDialogOpen(false);
      dispatch(LibraryActions.addMessages(selectedMessageType.selectedItem, medias));
    },
    [dispatch, setNewlyAdded, selectedMessageType.selectedItem]
  );

  useEffect(() => {
    if (tableContainer.current) {
      tableContainer.current.scrollTop = 0;
    }
  }, [newlyAdded, tableContainer.current]);

  useEffect(() => {
    setItems(libraryItems);
    setNewlyAdded([]);
  }, [libraryItems]);

  useEffect(() => {
    if (selected === undefined || selected === 0) {
      return;
    }

    const selectedDeleted = libraryItems.filter(i => i.mediaInfo.id === selected).length === 0;
    if (selectedDeleted && onSelection) {
      onSelection(0);
    }
  }, [libraryItems, selected, onSelection]);

  const handleBackFolderClick = useCallback(() => {
    dispatch(LibraryActions.popFolder());
  }, [currentFolder, dispatch]);

  const handleItemSelected = useCallback(
    (item: MessageLibraryItem) => {
      if (item.mediaInfo.isFolder) {
        dispatch(LibraryActions.pushFolder(item));
      } else if (!disableSelection) {
        if (variant === 'list') {
          history.push(`/library/message/${item.mediaInfo.id}`);
        } else if (onSelection) {
          onSelection(item.mediaInfo.id);
        }
      }
    },
    [history, onSelection, disableSelection]
  );

  const onSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const searchTerm = event.target.value;
      setItems(libraryItems.filter((i) => i.mediaInfo.name._contains(searchTerm)));
    },
    [libraryItems, setItems]
  );

  return (
    <DataGridContextProvider>
      <div className={classes.container}>
        <NavigatorToolbar
          currentFolder={currentFolder}
          hideControls={hideControls}
          hideSearch={variant === 'list'}
          onUpload={handleUploadClick}
          onDelete={handleDeleteClick}
          onFolderBack={handleBackFolderClick}
          onSort={handleSortClick}
          deleteDisabled={!selected}
        />
        <NavigatorBreadcrumbs typographyProps={{ variant: 'caption' }} classes={{ root: classes.breadcrumbsRoot }} />
        {props.variant === 'table' && (
          <Paper className={classes.tablePaper} >
            <NavigatorTable
              libraryItems={items}
              fetchingMessages={fetchingMessages}
              newlyAdded={newlyAdded}
              disableSelection={props.disableSelection}
              secondaryAction={props.secondaryAction}
              secondaryActionProps={props.secondaryActionProps}
              secondaryActionLabel={props.secondaryActionLabel}
              onItemSelected={handleItemSelected}
              currentFolder={currentFolder}
              controlledSelection={props.selected}
              controlledSelectionEnabled={props.selected !== undefined}
            />
          </Paper>
        )}
        {props.variant === 'list' && (
          <NavigatorList
            libraryItems={items}
            onItemSelected={handleItemSelected}
            fetchingMessages={fetchingMessages}
            newlyAdded={newlyAdded}
            secondaryAction={props.secondaryAction}
            secondaryActionProps={props.secondaryActionProps}
            onSearchChange={onSearchChange}
          />
        )}
      </div>

      <UploadDialog
        accept=".mp3"
        open={uploadDialogOpen}
        multiple
        filesAreContent
        folderId={currentFolder?.id}
        onFinish={onMessageUpload}
        onCancel={handleUploadCancel}
        title="Upload messages"
      />
      <ConfirmationDialog
        title={t('deleteMessageConfirmTitle')}
        open={deleteDialogOpen}
        content={t('deleteMessageContent')}
        onConfirm={onDelete}
        onCancel={onCancelDelete}
      />
    </DataGridContextProvider>
  );
};
