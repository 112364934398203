import { TreeItemState, TreeItemType } from 'addressing/duck';
import React, { PropsWithChildren, ReactNode } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { green, red } from '@material-ui/core/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RouterIcon from '@material-ui/icons/Router';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';

import { AddressingForceDeny, AddressingInheritDeny, LocationsGroup } from '../../components/icons';
import { TreeData } from '../duck';
import { useStyles } from './Addressing.jss';

export const LIGHT_GREEN: number = 0;
export const DARK_GREEN: number = 1;
export const DARK_RED: number = 2;
export const LIGHT_RED: number = 3;

const GreenCheckbox: any = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RedCheckbox: any = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const iconStyle = { fill: 'white', marginRight: 4 };

export const getEntityIcon = (node: TreeData): ReactNode => {
  const addressingIcons = new Map<TreeItemType, ReactNode>([
    [TreeItemType.Workgroup, <AccountTreeIcon style={iconStyle} />],
    [TreeItemType.Group, <LocationOnIcon style={iconStyle} />],
    [TreeItemType.SiteGroup, <LocationsGroup style={iconStyle} viewBox="0 0 32 24" />],
    [TreeItemType.Site, <LocationOnIcon style={iconStyle} />],
    [TreeItemType.Player, <RouterIcon style={iconStyle} />],
    [TreeItemType.Stream, <SettingsInputHdmiIcon style={iconStyle} />],
  ]);

  return addressingIcons.get(node.modelType);
};

export const AddressingNodeCheckboxIcon = (node: PropsWithChildren<TreeData>) => {
  switch (node.chckState) {
    case TreeItemState.InheritAllow:
      return <CheckBoxOutlinedIcon fontSize="small" />;
    case TreeItemState.InheritDeny:
      return <AddressingInheritDeny fontSize="small" />;
    case TreeItemState.Deny:
      return <AddressingForceDeny fontSize="small" />;
    case TreeItemState.Allow:
      return <CheckBoxIcon fontSize="small" />;
    default:
      throw new Error('Unknown TreeItemState');
  }
};

const emptyOnChange = () => null;

export const AddressingNodeCheckbox = (node: PropsWithChildren<TreeData>) => {
  const classes = useStyles();

  const props = {
    onChange: node.isProcessing ? emptyOnChange : node.onChange,
    checked: true,
    checkedIcon: AddressingNodeCheckboxIcon(node),
    disabled: node.isProcessing,
    className: classes.checkbox,
  };

  if (node.modelType === TreeItemType.FOLDER) {
    return <></>;
  }

  return node.chckState === TreeItemState.InheritDeny || node.chckState === TreeItemState.Deny ? (
    <>
      {node.isProcessing ? (
        <div className={classes.processingIcon}>
          <HourglassEmptyIcon fontSize="small" />
        </div>
      ) : (
        <RedCheckbox {...props} />
      )}
      {getEntityIcon(node)}
    </>
  ) : (
    <>
      {node.isProcessing ? (
        <div className={classes.processingIcon}>
          <HourglassEmptyIcon fontSize="small" />
        </div>
      ) : (
        <GreenCheckbox {...props} />
      )}
      {getEntityIcon(node)}
    </>
  );
};

export const AddressingNodeFormLabel = (props: PropsWithChildren<TreeData>) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        className={classes.formControl}
        control={<>{<AddressingNodeCheckbox {...props} />}</>}
        label={props.label}
      />
      {/* {props.isLinked ? (
        <Tooltip title="Connected to channel" aria-label="Connected to channel" placement="right-start">
          <SettingsIcon className={classes.gearIcon} />
        </Tooltip>
      ) : (
        <></>
      )} */}
    </>
  );
};
