import { MediaForms } from 'components';
import {
  PlaylistFormData,
  playlistFormDataToCreateDto,
  loopPlaylistFormSchema,
} from 'components/mediaEdit/forms';
import { Formik } from 'formik';
import { useFetchLibrary } from 'hooks';
import { LayoutSelectors } from 'layout';
import deepClone from 'lodash-es/cloneDeep';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormsUtils } from 'utils';
import { ZoneSelectors } from 'zone';
import { ZoneThunks } from 'zone/duck';

import { MediaDto, MessageType } from '@models';
import { MediaType } from '@models/MediaType';

import { AddressingContextProvider } from '../../addressing/context/addressing.context';
import { selectUpdatedAddressingRules } from '../../addressing/duck/selectors';
import { MSG } from '../../App.bootstrap';
import { useStyles } from './PlaylistEdit.jss';
import { PlaylistForm } from './PlaylistForm';
import { usePlaylistEditProps } from './props.hook';

import { useDebouncedCallback } from 'use-debounce';
import { EntityType } from 'react-tools';

export interface PlaylistEditProps {
  playlistId?: number;
  zoneId?: number;
  onCancel?: () => void;
}

export const PlaylistEdit: React.FunctionComponent<PlaylistEditProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { playlistId, zoneId } = usePlaylistEditProps(props);
  const newlyAddedPlaylistId = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);

  const playlist = useSelector((state) =>
    playlistId
      ? ZoneSelectors.selectPlaylist(state, Number(playlistId))
      : ZoneSelectors.selectPlaylist(state, newlyAddedPlaylistId)
  );
  const messageType = useSelector(LayoutSelectors.selectMessageType);
  const addressingRules = useSelector(selectUpdatedAddressingRules);

  useFetchLibrary();

  useEffect(() => {
    if (playlistId) {
      dispatch(ZoneThunks.fetchPlaylistInfo(playlistId));
    }
  }, [playlistId, dispatch]);

  const onSave = useCallback(
    (values: PlaylistFormData & { messages: MediaDto[] }) => {
      let addressing = addressingRules;

      // if this is a new media and there are no addressing rules, add force deny on workgroup MoodMedia
      if (!playlistId && addressing.length < 1) {
        addressing = [
          {
            idMediaChannel: zoneId,
            idMedia: playlistId,
            idEntity: 1,
            idEntityType: EntityType.Workgroup,
            deny: true,
          },
        ];
      }

      dispatch(
        ZoneThunks.savePlaylist(
          zoneId,
          playlistFormDataToCreateDto(values, messageType, playlistId),
          values.messages,
          deepClone(addressing)
        )
      );
    },
    [playlistId, zoneId, playlist, addressingRules]
  );

  const [debouncedSave] = useDebouncedCallback(
    (values: PlaylistFormData & { messages: MediaDto[] }) => onSave(values),
    300
  );

  const formInitialValues = useMemo(() => {
    return { ...FormsUtils.playlistToFormData(playlist), messages: [] };
  }, [playlist]);

  const MemoizedForm = useMemo(
    () => (
      <AddressingContextProvider {...{ mediaId: playlistId, channelId: zoneId, workgroupId: MSG.workgroupId }}>
        <div className={classes.playlistContainer}>
          <Formik
            initialValues={formInitialValues}
            enableReinitialize
            onSubmit={debouncedSave}
            validationSchema={
              messageType === MessageType.Overhead ? MediaForms.eventPlaylistFormSchema : loopPlaylistFormSchema
            }
            validateOnBlur
            validateOnChange
          >
            <PlaylistForm playlistId={playlistId} messageType={messageType} onCancel={props.onCancel} />
          </Formik>
        </div>
      </AddressingContextProvider>
    ),
    [playlist, playlistId, props.onCancel, addressingRules]
  );

  return MemoizedForm;
};
