import { useAddressingContextChannelId, useAddressingContextWorkgroupId } from 'addressing/context/hooks';
import { clearAddressingFull } from 'addressing/duck/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAddressingStructure, selectAddressingState, selectAddressingStructure, TreeNode } from '../duck';
import { toggleNodeExpand } from '../duck/actions';

const useAddressingStructure = (channelId: number = 0, workgroupId: number = 0) => {
  const dispatch = useDispatch();
  const addressingStructureState = useSelector(selectAddressingStructure);
  const addressingState = useSelector(selectAddressingState);
  const contextChannelId = useAddressingContextChannelId();
  const contextWorkgrouplId = useAddressingContextWorkgroupId();
  channelId = channelId ? channelId : contextChannelId;
  workgroupId = workgroupId ? workgroupId : contextWorkgrouplId;

  useEffect(() => {
    const isFetching = addressingStructureState.fetchStatus.isFetching;
    const fetchComplete = addressingStructureState.fetchStatus.complete;

    // we need to fetch addressing structure
    if (!isFetching && !fetchComplete) {
      dispatch(fetchAddressingStructure(channelId, workgroupId));
    } else {
      /* if channelId/workroupId has changed,
      clear the entire addressing state to
      trigger refetching for both structure and rules */
      if (channelId !== addressingState.channelId || workgroupId !== addressingState.workgroupId) {
        dispatch(clearAddressingFull());
      }
    }
  }, [
    addressingStructureState.fetchStatus.complete,
    addressingStructureState.fetchStatus.isFetching,
    addressingState.workgroupId,
    addressingState.channelId,
    channelId,
    workgroupId,
  ]);

  const toggleExpandNode = useCallback((node: TreeNode) => {
    dispatch(toggleNodeExpand(node.$$hashKey));
  }, []);

  return {
    processedData: addressingStructureState.processedData,
    fetchStatus: addressingStructureState.fetchStatus,
    processingStatus: addressingStructureState.processingStatus,
    toggleExpandNode: toggleExpandNode,
  };
};

export default useAddressingStructure;
