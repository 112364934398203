import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6)
    },
    skeletonContainer: {
      marginLeft: theme.spacing(2),
    },
    skeletonItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    listSubheader: {
      background: theme.palette.background.default,
      fontFamily: theme.typography.caption.fontFamily,
      fontSize: theme.typography.caption.fontSize
    },
    listItemRoot: {
      [theme.breakpoints.up('lg')]: {
        borderTopRightRadius: '24px',
        borderBottomRightRadius: '24px',
      }
    },
    listItemSelectedLibrary: {
      borderLeft: `4px solid ${theme.palette.secondary.main}`,
    },
    listItemSelectedZone: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    listItemPrimary: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    version: {
      marginTop: 'auto',
      textAlign: 'left',
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
  })
);
