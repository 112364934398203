import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';

import { List, ListItem, ListItemText } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotesIcon from '@material-ui/icons/Notes';

import { useStyles } from './FormMediaNameListItem.jss';

export const FormMediaDescriptionListItem: React.FunctionComponent = (props) => {
  const [field, meta, helpers] = useField('description');
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value);
    },
    [helpers.setValue]
  );
  const [t] = useTranslation();
  return (
    <ListItem>
      <ListItemIcon>
        <NotesIcon />
      </ListItemIcon>
      <ListItemText>
        <TextFieldInnerWrapper
          fullWidth
          multiline
          rowsMax={3}
          inputProps={{ maxLength: 500 }}
          variant="filled"
          label={t('media.description')}
          value={field.value}
          onChange={onChange}
        />
      </ListItemText>
    </ListItem>
  );
};
