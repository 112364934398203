import { IdName } from 'react-tools';

import { MediaDto, MessageType } from '@models';

export const libraryAreaName = 'library';

export interface LibraryState {
  items: MessageLibraryItem[];
  folderPath: FolderPathItem[];
  fetchingLibrary: boolean;
  fetchingMessage: boolean;
  savingMessage: boolean;
  deletingMessages: boolean;
}

export interface FolderPathItem {
  id: number;
  name: string;
  level: number;
  messageType?: MessageType;
}

export interface MediaReferencesPayload {
  mediaIds: [];
  OnlySharedReferences: boolean;
}

export interface MessageLibraryItem {
  level: number;
  messageType: MessageType;
  mediaInfo: MediaDto;
}

export enum ActionTypes {
  ADD_MESSAGES = 'ADD_MESSAGES',
  PUSH_FOLDER = 'PUSH_FOLDER',
  POP_FOLDER = 'POP_FOLDER',
  RESET_ROOT_FOLDER = 'RESET_ROOT_FOLDER',
  FETCH_LIBRARY_REQUEST = 'FETCH_LIBRARY_REQUEST',
  FETCH_LIBRARY_SUCCESS = 'FETCH_LIBRARY_SUCCESS',
  DELETE_MESSAGES_REQUEST = 'DELETE_MESSAGES_REQUEST',
  DELETE_MESSAGES_SUCCESS = 'DELETE_MESSAGES_SUCCESS',
  FETCH_MESSAGE_INFO_REQUEST = 'FETCH_MESSAGE_INFO_REQUEST',
  FETCH_MESSAGE_INFO_SUCCESS = 'FETCH_MESSAGE_INFO_SUCCESS',
  SAVE_MESSAGE_INFO_REQUEST = 'SAVE_MESSAGE_INFO_REQUEST',
  SAVE_MESSAGE_INFO_SUCCESS = 'SAVE_MESSAGE_INFO_SUCCESS',
}
