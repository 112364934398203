import { MediaDto } from '@models';
import { ZonePlaylistDto } from '@models/PlaylistDto';

export const zoneAreaName = 'zone';

export interface Zone {
  zoneId: number;
  zoneName: string;
  workgroupId: number;
  contentType: ChannelContentType;
}
export enum ChannelContentType {
  Overhead = 2,
  OnHold = 5,
}

export interface ZoneState {
  overheadZones: Zone[];
  onHoldZones: Zone[];
  initialized: boolean;
  selectedZonePlaylists: ZonePlaylistDto[];
  selectedPlaylistMessages: MediaDto[];

  fetchingZones: boolean;
  fetchingZonePlaylists: boolean;
  fetchingPlaylist: boolean;
  savingPlaylist: boolean;
  deletingZonePlaylist: boolean;
  newlyCreatedPlaylistId: number;
}

export enum ActionTypes {
  FETCH_ZONES_REQUEST = 'FETCH_ZONES_REQUEST',
  FETCH_ZONES_SUCCESS = 'FETCH_ZONES_SUCCESS',
  FETCH_ZONE_PLAYLISTS_REQUEST = 'FETCH_ZONE_PLAYLISTS_REQUEST',
  FETCH_ZONE_PLAYLISTS_SUCCESS = 'FETCH_ZONE_PLAYLISTS_SUCCESS',
  FETCH_PLAYLIST_REQUEST = 'FETCH_PLAYLIST_REQUEST',
  FETCH_PLAYLIST_SUCCESS = 'FETCH_PLAYLIST_SUCCESS',
  FETCH_PLAYLIST_FAIL = 'FETCH_PLAYLIST_FAIL',
  SAVE_PLAYLIST_REQUEST = 'SAVE_PLAYLIST_REQUEST',
  SAVE_PLAYLIST_SUCCESS = 'SAVE_PLAYLIST_SUCCESS',
  SAVE_PLAYLIST_FAIL = 'SAVE_PLAYLIST_FAIL',
  DELETE_ZONE_PLAYLIST_REQUEST = 'DELETE_ZONE_PLAYLIST_REQUEST',
  DELETE_ZONE_PLAYLIST_SUCCESS = 'DELETE_ZONE_PLAYLIST_SUCCESS',
  DELETE_ZONE_PLAYLIST_FAIL = 'DELETE_ZONE_PLAYLIST_FAIL',
  CLEAR_NEWLY_CREATED_PLAYLIST_ID = 'CLEAR_NEWLY_CREATED_PLAYLIST_ID',
  SET_SELECTED_PLAYLIST = 'SET_SELECTED_PLAYLIST',
}
