import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export interface FormListCollapseItemProps {
  labelKey: string;
  value: React.ReactElement | string;
  noValueKey?: string;
  icon?: React.ReactElement;
}

export const FormListCollapseItem: React.FunctionComponent<FormListCollapseItemProps> = (props) => {
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleExpandClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem onClick={handleExpandClick} button>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <ListItemText
          inset={!props.icon}
          primaryTypographyProps={{component: 'div'}}
          primary={
            <Typography variant="caption" color={open ? 'primary' : 'textSecondary'}>
              {t(props.labelKey)}
            </Typography>
          }
          secondary={
            typeof props.value === 'string' ? (
              <Typography color="textPrimary" variant="body1">
                {props.value}
              </Typography>
            ) : (
              props.value
            )
          }
        ></ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
};
