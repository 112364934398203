import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';

import * as actions from './actions';
import { AddressingApi } from './api';

export const fetchAddressingStructure = (channelId: number, workgroupId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchAddressingStructureRequest(channelId, workgroupId));
    try {
      const addressingStructure = await AddressingApi.fetchAddressingStructure(channelId, workgroupId);
      dispatch(actions.fetchAddressingStructureSuccess(channelId, workgroupId, addressingStructure));
      dispatch(actions.buildAddressingTreeStart(addressingStructure, workgroupId));
    } catch (err) {
      dispatch(actions.fetchAddressingStructureError(err));
      dispatch(pushNotification(i18next.t('addressingStructure.fetch.error'), 'error'));
    }
  };
};

export const fetchAddressingRules = (channelId: number, mediaId: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchAddressingRulesRequest(channelId, mediaId));
    try {
      const addressingRules = await AddressingApi.fetchAddressingRules(channelId, mediaId);
      dispatch(actions.fetchAddressingRulesSuccess(channelId, mediaId, addressingRules));
    } catch (err) {
      dispatch(actions.fetchAddressingRulesError(err));
      dispatch(pushNotification(i18next.t('addressingRules.fetch.error'), 'error'));
    }
  };
};
