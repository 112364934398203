import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: theme.spacing(2),
    },
    toolbarButton: {
      marginLeft: theme.spacing(2),
    },
    spacer: {
      flexGrow: 1,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(9),
      right: theme.spacing(2),
    },
    buttonProgress: {
      color: theme.palette.type === 'dark' ? 'secondary' : 'inherit',
    },
  })
);
