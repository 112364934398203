import { MSG } from 'App.bootstrap';
import classNames from 'classnames';
import { Icons, ListItemLink } from 'components';
import { useDirty } from 'dirty';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ZoneSelectors, ZoneThunks } from 'zone';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import { MessageType } from '@models';

import { LayoutActions, LayoutSelectors } from '../duck';
import { LayoutState, MainLayoutArea } from '../duck/types';
import { useRedirect } from '../use-redirect';
import { useStyles } from './DrawerContent.jss';
import { useRoutes } from './useRoutes';
import { getDefaultLayoutStateAndUrl, getDirtyDialogContent, getLayoutFromZones } from './utils';
import { ZoneListSkeleton } from './ZoneListSkeleton';

export const DrawerContent: React.FunctionComponent = () => {
  useRedirect();
  useRoutes();
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const fetchingZones = useSelector(ZoneSelectors.selectFetchingZones);
  const zones = useSelector(ZoneSelectors.selectZones);
  const layoutState = useSelector(LayoutSelectors.selectLayoutState);
  const [nextSelected, setNextSelected] = useState(getDefaultLayoutStateAndUrl());

  useEffect(() => {
    dispatch(ZoneThunks.fetchZones());
  }, []);

  const onDirtyConfirm = useCallback(() => {
    dispatch(LayoutActions.selectMainArea(nextSelected.selectedMainArea, nextSelected.selectedItem));
    history.push(nextSelected.url);
  }, [history, dispatch, nextSelected]);

  const onDirtyCancel = useCallback(() => {
    setNextSelected({
      selectedMainArea: MainLayoutArea.Unauthorized,
      selectedItem: 0,
      url: '',
      splashScreenShown: true,
    });
  }, [setNextSelected]);

  const [dirty, openDialog] = useDirty(onDirtyConfirm, onDirtyCancel);

  const onZoneSelection = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const zoneId = Number(event.currentTarget.id);
      const layout = getLayoutFromZones(zones, zoneId);

      if (!layout) {
        return;
      }

      if (dirty) {
        openDialog(undefined, getDirtyDialogContent(layoutState));
        setNextSelected({
          ...layout,
          url: `/zone/${zoneId}`,
        });
      } else {
        dispatch(LayoutActions.selectMainArea(layout.selectedMainArea, zoneId));
        history.push(`/zone/${zoneId}`);
      }
    },
    [dispatch, zones, dirty, openDialog, layoutState]
  );

  const onLibrarySelection = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const messageType = Number(event.currentTarget.id);
      if (dirty) {
        openDialog(undefined, getDirtyDialogContent(layoutState));
        setNextSelected({
          selectedMainArea: messageType,
          selectedItem: 0,
          url: '/library',
          splashScreenShown: true,
        });
      } else {
        dispatch(LayoutActions.selectMainArea(messageType, 0));
        history.push(`/library`);
      }
    },
    [dispatch, dirty, openDialog]
  );

  return (
    <>
      <div>
        <ListItem
          classes={{ root: classNames(classes.listItemRoot, classes.homeButton) }}
          button
          onClick={() => MSG.homeFn()}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={t('home')} />
        </ListItem>
        <List subheader={<ListSubheader className={classes.listSubheader}>{t('library').toUpperCase()}</ListSubheader>}>
          {zones.overHead.length > 0 && (
            <ListItemLink
              primary={t('overHead')}
              icon={<Icons.OverheadIcon />}
              onClick={onLibrarySelection}
              id={MessageType.Overhead.toString()}
              selected={layoutState.selectedMainArea === MainLayoutArea.LibraryOverhead}
              classes={{ root: classes.listItemRoot, selected: classes.listItemSelectedLibrary }}
            />
          )}
          {zones.onHold.length > 0 && (
            <ListItemLink
              icon={<Icons.OnHoldIcon />}
              primary={t('onHold')}
              onClick={onLibrarySelection}
              id={MessageType.OnHold.toString()}
              selected={layoutState.selectedMainArea === MainLayoutArea.LibraryOnHold}
              classes={{ root: classes.listItemRoot, selected: classes.listItemSelectedLibrary }}
            />
          )}
        </List>
        {zones.overHead.length > 0 && (
          <>
            <List
              subheader={
                <ListSubheader className={classes.listSubheader}>{t('zonesOverHead').toUpperCase()}</ListSubheader>
              }
            >
              {fetchingZones ? (
                <ZoneListSkeleton />
              ) : (
                zones.overHead.map((z) => (
                  <ListItemLink
                    key={z.zoneId}
                    primary={z.zoneName}
                    selected={
                      layoutState.selectedMainArea === MainLayoutArea.ZonesOverhead &&
                      layoutState.selectedItem === z.zoneId
                    }
                    primaryTextClasses={{ primary: classes.listItemPrimary }}
                    classes={{ root: classes.listItemRoot, selected: classes.listItemSelectedZone }}
                    onClick={onZoneSelection}
                    id={z.zoneId.toString()}
                  />
                ))
              )}
            </List>
          </>
        )}
        {zones.onHold.length > 0 && (
          <List
            subheader={
              <ListSubheader className={classes.listSubheader}>{t('zonesOnHold').toUpperCase()}</ListSubheader>
            }
          >
            {fetchingZones ? (
              <ZoneListSkeleton />
            ) : (
              zones.onHold.map((z) => (
                <ListItemLink
                  key={z.zoneId}
                  primary={z.zoneName}
                  selected={
                    layoutState.selectedMainArea === MainLayoutArea.ZonesOnHold && layoutState.selectedItem === z.zoneId
                  }
                  onClick={onZoneSelection}
                  id={z.zoneId.toString()}
                  classes={{ root: classes.listItemRoot, selected: classes.listItemSelectedZone }}
                  primaryTextClasses={{ primary: classes.listItemPrimary }}
                />
              ))
            )}
          </List>
        )}
      </div>
      <Typography color="textSecondary" variant="caption" className={classes.version}>
        v{MSG.version}
        <br />
      </Typography>
    </>
  );
};
