import { LayoutSelectors } from 'layout';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HelpDialog } from 'react-tools';

import { IconButton, Toolbar, Tooltip } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Trash from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import { MessageType } from '@models';

import { useStyles } from './Playlists.jss';

interface PlaylistsToolbarProps {
  onAddClick: () => void;
  onDeleteClick: () => void;
  canDelete: boolean;
}

export const PlaylistsToolbar = ({ onAddClick, onDeleteClick, canDelete }: PlaylistsToolbarProps) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [helpOpen, setHelpOpen] = useState(false);
  const onHelpOpen = useCallback(() => setHelpOpen(true), [setHelpOpen]);
  const onHelpClose = useCallback(() => setHelpOpen(false), [setHelpOpen]);
  const messageType = useSelector(LayoutSelectors.selectMessageType);
  
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.flexSpacer}></div>
      <Tooltip title={t<string>('createZonePlaylist')}>
        <IconButton onClick={onAddClick}>
          <Add />
        </IconButton>
      </Tooltip>
      <Tooltip title={t<string>('deleteZonePlaylist')}>
        <IconButton onClick={onDeleteClick} disabled={!canDelete}>
          <Trash />
        </IconButton>
      </Tooltip>
      <IconButton onClick={onHelpOpen}>
        <HelpIcon />
      </IconButton>
      {messageType === MessageType.OnHold && <HelpDialog isOpen={helpOpen} type='onHoldPlaylist' closeDialog={onHelpClose}/>}
      {messageType === MessageType.Overhead && <HelpDialog isOpen={helpOpen} type='overheadPlaylist' closeDialog={onHelpClose}/>}
    </Toolbar>
  );
};
