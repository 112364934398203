import { DirtyActions, DirtySelectors } from 'dirty';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionTypes } from './types';

type CallbackFn = () => void;
type OpenDialogFn = (title?: string, content?: string) => {
  type: ActionTypes.OPEN_DIALOG;
  payload: {
    issuer: string;
    title: string | undefined;
    content: string | undefined;
  }
};
type UseDirtyFn = (onConfirm?: CallbackFn, onCancel?: CallbackFn) => [boolean, OpenDialogFn];

export const useDirty: UseDirtyFn = (onConfirm, onCancel) => {
  const dispatch = useDispatch();
  const [idn] = useState(Math.random().toString(16).slice(2, 6));
  const dirty = useSelector(DirtySelectors.selectDirty);
  const confirmClicked = useSelector(DirtySelectors.selectConfirmClicked);
  const cancelClicked = useSelector(DirtySelectors.selectCancelClicked);
  const issuer = useSelector(DirtySelectors.selectIssuer);

  const openDialog = useCallback((title?: string, content?: string) =>
    dispatch(DirtyActions.openDialog(idn, title, content)), [dispatch, idn]);

  useEffect(() => {
    if (confirmClicked && (issuer === idn)) {
      dispatch(DirtyActions.clearDialog());
      dispatch(DirtyActions.setDirty(false));

      if (onConfirm) {
        onConfirm();
      }
    }
  }, [confirmClicked, onConfirm, issuer, idn]);

  useEffect(() => {
    if (cancelClicked && (issuer === idn)) {
      dispatch(DirtyActions.clearDialog());

      if (onCancel) {
        onCancel();
      }
    }
  }, [cancelClicked, onCancel, issuer, idn]);

  return [dirty, openDialog];
};
