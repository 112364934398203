import React from 'react';

import { Skeleton } from '@material-ui/lab';

import { useStyles } from './DrawerContent.jss';

export const ZoneListSkeleton: React.FunctionComponent = React.memo(() => {
    const classes = useStyles();
    return (
      <div className={classes.skeletonContainer}>
        <Skeleton className={classes.skeletonItem} variant="text" width={160} />
        <Skeleton className={classes.skeletonItem} variant="text" width={140} />
        <Skeleton className={classes.skeletonItem} variant="text" width={190} />
      </div>
    );
  });