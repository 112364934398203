import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skeletonContainer: {
      marginLeft: theme.spacing(2),
    },
    skeletonItem: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    container: {
      overflow: 'hidden',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    list: {
      overflowX: 'auto',
      marginTop: theme.spacing(2),
    },
  })
);
