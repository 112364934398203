import { clearAddressingFull } from 'addressing/duck/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { AddressingContextData } from '../duck';
import { clearAddressingRules } from '../duck/actions';

const DefaultAddressingContextValue: AddressingContextData = {
  mediaId: 0,
  channelId: 0,
  workgroupId: 0,
};

export const AddressingGridContext = React.createContext<AddressingContextData>(DefaultAddressingContextValue);

export const AddressingContextProvider: React.FunctionComponent<AddressingContextData> = (props) => {
  const dispatch = useDispatch();

  // clean both addressing rules and addressing structure when unmounting this context
  useEffect(() => {
    return () => {
      console.info('clearAddressingFull');
      dispatch(clearAddressingFull());
    };
  }, []);

  // clean current addressing rules from state when changing mediaId selection
  useEffect(() => {
    return () => {
      console.info('clearAddressingRules');
      dispatch(clearAddressingRules());
    };
  }, [props.mediaId]);

  return (
    <AddressingGridContext.Provider
      value={{
        mediaId: props.mediaId,
        channelId: props.channelId,
        workgroupId: props.workgroupId,
      }}
    >
      {props.children}
    </AddressingGridContext.Provider>
  );
};
