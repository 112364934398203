import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: {
      display: 'flex',
    },
    flexSpacer: {
      flexGrow: 1,
    },
    list: {
      flexDirection: 'column',
      height: 'calc(100% - 64px)',
      overflow: 'auto',
    },
  })
);
