import { DirtyActions, useDirty } from 'dirty';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';

import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { EmptySelection } from '../library/list/EmptySelection';
import { ZoneSelectors, ZoneThunks } from './duck';
import { clearNewlyAddedPlaylistId } from './duck/actions';
import { PlaylistEdit } from './playlistEdit/PlaylistEdit';
import { Playlists } from './playlists/Playlists';
import { PlaylistsToolbar } from './playlists/Toolbar';
import { useStyles } from './ZoneEdit.jss';

interface ZoneRouteParams {
  zoneId: string;
}

export interface ZoneProps {}

export const ZoneEdit: React.FunctionComponent<ZoneProps> = (props) => {
  const { zoneId } = useParams<ZoneRouteParams>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false);
  const history = useHistory();
  const newlyAddedPlaylistId = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);
  const newlyAddedPlaylist = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);

  useEffect(() => {
    if (newlyAddedPlaylistId) {
      setSelected(newlyAddedPlaylistId);
      setIsCreatingPlaylist(false);
    }
  }, [newlyAddedPlaylistId]);

  useEffect(() => {
    setSelected(newlyAddedPlaylist);
  }, [newlyAddedPlaylist]);

  useEffect(() => {
    dispatch(ZoneThunks.fetchZonePlaylists(Number(zoneId)));
    setSelected(undefined);
    setIsCreatingPlaylist(false);
  }, [zoneId, dispatch]);

  const [selected, setSelected] = useState<number | undefined>(0);

  const [nextSelected, setNextSelected] = React.useState(0);

  const onDirtyConfirm = useCallback(() => {
    setSelected(nextSelected);
    setNextSelected(0);
  }, [setSelected, setNextSelected, nextSelected]);

  const onDirtyCancel = useCallback(() => {
    setNextSelected(0);
  }, [setNextSelected]);

  const [dirty, openDialog] = useDirty(onDirtyConfirm, onDirtyCancel);

  const handleSelect = useCallback(
    (id: number) => {
      if (dirty) {
        openDialog();
        setNextSelected(id);
      } else {
        setSelected(id);
      }
    },
    [dirty]
  );

  const addPlaylist = useCallback(() => {
    setSelected(undefined);
    setIsCreatingPlaylist(true);
    dispatch(clearNewlyAddedPlaylistId());
  }, [history]);

  const navigateToAddPlaylist = useCallback(() => {
    dispatch(clearNewlyAddedPlaylistId());
    history.push(`/zone/${zoneId}/create`);
  }, [history, zoneId]);

  const deletePlaylist = useCallback(() => {
    setConfirmDeleteOpen(true);
  }, [selected]);

  const onDelete = useCallback(() => {
    if (selected) {
      dispatch(ZoneThunks.deleteZonePlaylist(parseInt(zoneId), selected));
      dispatch(DirtyActions.setDirty(false));
      setSelected(undefined);
      setConfirmDeleteOpen(false);
    }
  }, [selected, zoneId]);

  const onCancelDelete = useCallback(() => setConfirmDeleteOpen(false), []);
  const onCancelEdit = useCallback(() => {
    setIsCreatingPlaylist(false);
    setSelected(undefined);
  }, []);

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <PlaylistsToolbar onAddClick={navigateToAddPlaylist} onDeleteClick={onDelete} canDelete={!!selected} />
        <Playlists zoneId={parseInt(zoneId)} />
      </Hidden>
      <Hidden smDown>
        <Grid className={classes.grid} container spacing={3}>
          <Grid item xs={6} className={classes.gridItem}>
            <PlaylistsToolbar onAddClick={addPlaylist} canDelete={!!selected} onDeleteClick={deletePlaylist} />
            <Playlists zoneId={parseInt(zoneId)} selected={selected} onSelected={handleSelect} />
          </Grid>
          <Grid item xs={6} className={classes.gridItem}>
            <Paper className={classes.gridItemPaper}>
              {selected || isCreatingPlaylist ? (
                <PlaylistEdit playlistId={selected} zoneId={parseInt(zoneId)} onCancel={onCancelEdit} />
              ) : (
                <div className={classes.emptyPlaylistEditContainer}>
                  <EmptySelection
                    title={'message.emptyZonePlaylistSelectionTitle'}
                    subtitle={'message.emptyZonePlaylistSelectionSubtitle'}
                  />
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Hidden>
      <ConfirmationDialog
        title={t('deleteMessageConfirmTitle')}
        open={confirmDeleteOpen}
        content={t('deleteMessageContent')}
        onConfirm={onDelete}
        onCancel={onCancelDelete}
      />
    </div>
  );
};
