import {
  endOfDay,
  format,
  isAfter,
  parseISO,
  parseJSON,
  startOfDay
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import i18next from 'i18next';
import {
  convertHMSToSeconds,
  secondsFormatForRepeatFrequency
} from 'utils/dateTimeUtils';
import * as yup from 'yup';

import { MediaDto, MessageType, PlaylistUpdateDto } from '@models';
import { MediaType } from '@models/MediaType';
import {
  EventType,
  RepeatFrequency,
  ZonePlaylistDto
} from '@models/PlaylistDto';

export interface MediaFormData {
  name: string;
  description: string;
  scheduling: {
    startDate: Date;
    endDate: Date;
    startTime: Date;
    endTime: Date;
    diffusionDays: number;
  };
  soundVolume: number;
  duration: number | undefined;
}

export const mediaFormSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(i18next.t('media.validators.name'))
    .max(255, i18next.t('media.validators.maxLength', { length: 255 })),
  description: yup.string().max(500, i18next.t('media.validators.maxLength', { length: 500 })),
  scheduling: yup.object().shape({
    startDate: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endDate: yup
      .date()
      .required()
      .min(yup.ref('startDate'), i18next.t('media.validators.scheduling.date'))
      .typeError(i18next.t('media.validators.invalidDate')),
    startTime: yup.date().required().typeError(i18next.t('media.validators.invalidDate')),
    endTime: yup
      .date()
      .required()
      .when('startTime', (startTime: Date, schema: yup.DateSchema) =>
        schema.test('min', i18next.t('media.validators.scheduling.time'), (endTime: Date | undefined) => {
          const now = new Date();
          if (!endTime) {
            return true;
          }
          startTime.setDate(now.getDate());
          endTime.setDate(now.getDate());

          startTime.setMonth(now.getMonth());
          endTime.setMonth(now.getMonth());

          startTime.setFullYear(now.getFullYear());
          endTime.setFullYear(now.getFullYear());

          return isAfter(endTime, startTime);
        })
      )
      .typeError(i18next.t('media.validators.invalidDate')),
    diffusionDays: yup.number().min(1, i18next.t('media.validators.scheduling.diffusionDays')),
  }),
});

export const eventPlaylistFormSchema = mediaFormSchema.concat(
  yup.object().shape({
    eventType: yup.number().required(),
    repeatFrequency: yup.number().required(),
    isRandom: yup.boolean(),
    maxNumberOfMessages: yup.number().min(0),
    repeatNumberOfTracks: yup
      .number()
      .when('repeatFrequency', (repeatFrequency: RepeatFrequency, schema: yup.NumberSchema) =>
        repeatFrequency === RepeatFrequency.RepeatAfterNumberOfTracks
          ? schema.required('media.validators.scheduling.repeatNumberOfTracks').min(1).max(100)
          : schema.notRequired()
      ),
    repeatInterval: yup
      .number()
      .when('repeatFrequency', (repeatFrequency: RepeatFrequency, schema: yup.NumberSchema) => {
        return repeatFrequency === RepeatFrequency.RepeatHourly
          ? schema.required('media.validators.scheduling.repeatInterval').min(1).max(86340)
          : schema.notRequired();
      }),
  })
);

export const loopPlaylistFormSchema = mediaFormSchema.concat(
  yup.object().shape({
    isRandom: yup.boolean(),
  })
);

export interface PlaylistFormData extends MediaFormData {
  eventType: EventType;
  repeatFrequency: RepeatFrequency;
  isRandom: boolean;
  maxNumberOfMessages: number;
  repeatNumberOfTracks: number | undefined;
  repeatInterval?: number | undefined;
}

const getDefaultEndDate = () => {
  let now = new Date();
  now.setFullYear(2099);
  now = startOfDay(now);
  return now;
};

const getDefaultStartTime = () => startOfDay(new Date());

const getDefaultEndTime = () => endOfDay(new Date());

export const mediaDtoToFormData = (media: MediaDto | undefined): MediaFormData => {
  const formData = {
    name: media ? media.name : '',
    description: media ? media.description : '',
    scheduling: {
      startDate: media
        ? media.startDate
          ? utcToZonedTime(parseJSON(media.startDate), 'UTC')
          : new Date()
        : new Date(),
      endDate: media
        ? media.endDate
          ? utcToZonedTime(parseJSON(media.endDate), 'UTC')
          : getDefaultEndDate()
        : getDefaultEndDate(),
      startTime: media ? parseISO('0001-01-01T' + media.startTime) : getDefaultStartTime(),
      endTime: media ? parseISO('0001-01-01T' + media.endTime) : getDefaultEndTime(),
      diffusionDays: media ? media.diffusionDays : 127,
    },
    soundVolume: media && media.properties ? media.properties.soundVolume : 100,
    duration: media?.duration || undefined,
  };
  return formData;
};

export const playlistToFormData = (playlist?: ZonePlaylistDto): PlaylistFormData =>
  playlist
    ? {
        ...mediaDtoToFormData(playlist),
        eventType: playlist.eventType,
        repeatFrequency: playlist.repeatFrequency,
        isRandom: playlist.isRandom,
        maxNumberOfMessages: playlist.maxNoOfElements,
        repeatNumberOfTracks: playlist.repeatNumberOfTracks,
        repeatInterval: convertHMSToSeconds(playlist.repeatInterval),
      }
    : {
        ...mediaDtoToFormData(playlist),
        eventType: EventType.AsSoonAs,
        repeatFrequency: RepeatFrequency.RepeatDaily,
        isRandom: false,
        maxNumberOfMessages: 0,
        repeatNumberOfTracks: undefined,
        repeatInterval: undefined,
      };

export const playlistFormDataToCreateDto = (
  playlistInfo: PlaylistFormData,
  messageType?: MessageType,
  playlistId?: number
): PlaylistUpdateDto => ({
  owner: '',
  created: new Date(),
  startDate: format(playlistInfo.scheduling.startDate, 'yyyy-MM-dd 00:00:00'),
  endDate: format(playlistInfo.scheduling.endDate, 'yyyy-MM-dd 00:00:00'),
  startTime: playlistInfo.scheduling.startTime ? format(playlistInfo.scheduling.startTime, 'HH:mm:ss') : '00:00:00',
  endTime: playlistInfo.scheduling.endTime ? format(playlistInfo.scheduling.endTime, 'HH:mm:ss') : '00:00:00',
  diffusionDays: playlistInfo.scheduling.diffusionDays,
  isLocalInput: false,
  timezone: '',
  endMedia: false,
  origin: 1,
  description: playlistInfo.description,
  eventType: messageType === MessageType.Overhead ? playlistInfo.eventType : undefined,
  name: playlistInfo.name,
  repeatFrequency: playlistInfo.repeatFrequency,
  id: playlistId,
  repeatInterval: playlistInfo.repeatInterval
    ? secondsFormatForRepeatFrequency(playlistInfo.repeatInterval)
    : undefined,
  repeatNumberOfTracks: playlistInfo.repeatNumberOfTracks,
  maxNoOfElements: playlistInfo.maxNumberOfMessages,
  isRandom: playlistInfo.isRandom,
  mediaType: messageType === MessageType.Overhead ? MediaType.EventPlaylist : MediaType.LoopPlaylist,
});
