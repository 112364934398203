import { FormListCollapseItem, Icons, SchedulingListItems } from 'components';
import { PlaylistFormData } from 'components/mediaEdit/forms';
import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';

import { List, ListItemText, RadioGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItem from '@material-ui/core/ListItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import PlayFrequencyIcon from '@material-ui/icons/Update';
import { MessageType } from '@models';
import { EventType, RepeatFrequency } from '@models/PlaylistDto';

import { useStyles } from './Schedule.jss';
import { MinutesAndSecondsControl } from './MinuteSecondsControl';

export interface ScheduleProps {
  messageType?: MessageType;
}

const eventTypeLabelKey = (value: EventType) => {
  switch (value) {
    case EventType.AsSoonAs:
      return 'playMode.afterCurrentSong';
    case EventType.ExactTime:
      return 'playMode.interruptSong';
    case EventType.Overlay:
      return 'playMode.overlay';
    default:
      return '';
  }
};

const repeatFrequencyLabelKey = (value: RepeatFrequency) => {
  switch (value) {
    case RepeatFrequency.RepeatDaily:
      return 'playFrequency.onceDaily';
    case RepeatFrequency.RepeatHourly:
      return 'playFrequency.timeInterval';
    case RepeatFrequency.RepeatAfterNumberOfTracks:
      return 'playFrequency.playbackInterval';
    default:
      return '';
  }
};

export const Schedule: React.FunctionComponent<ScheduleProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const messageType = useMemo(
    () => (props.messageType ? props.messageType : MessageType.Overhead),
    [props.messageType]
  );

  const [playAll, setPlayAll] = useState(true);

  const { values, setFieldValue, errors } = useFormikContext<PlaylistFormData>();

  const onPlayAllChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setPlayAll(checked);

      if (checked) {
        setFieldValue('maxNumberOfMessages', 0);
      } else {
        setFieldValue('maxNumberOfMessages', values.maxNumberOfMessages || 1);
      }
    },
    [setPlayAll, values.maxNumberOfMessages]
  );

  useEffect(() => {
    if (values.maxNumberOfMessages < 1) {
      setPlayAll(true);
    } else {
      setPlayAll(false);
    }
  }, [values.maxNumberOfMessages]);

  const onMaxNumberOfMessagesChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);
      const actualValue = newValue < 1 ? 1 : newValue;
      setFieldValue('maxNumberOfMessages', 1);

      setTimeout(() => setFieldValue('maxNumberOfMessages', actualValue));
    },
    [setFieldValue]
  );

  const onEventTypeChange = useCallback(
    (_, value: string) => setFieldValue('eventType', Number(value)),
    [setFieldValue]
  );
  const onRepeatFrequencyChange = useCallback(
    (_, value: string) => {
      setFieldValue('repeatFrequency', Number(value));

      const repeatFrequency: RepeatFrequency = Number(value);
      switch (repeatFrequency) {
        case RepeatFrequency.RepeatAfterNumberOfTracks:
          setFieldValue('repeatInterval', undefined);
          setFieldValue('repeatNumberOfTracks', 3, true);
          break;
        case RepeatFrequency.RepeatHourly:
          setFieldValue('repeatInterval', 15 * 60, true);
          setFieldValue('repeatNumberOfTracks', undefined);
          break;
        case RepeatFrequency.RepeatDaily:
        case RepeatFrequency.NoRepeat:
          setFieldValue('repeatInterval', undefined);
          setFieldValue('repeatNumberOfTracks', undefined);
      }
    },
    [setFieldValue]
  );
  const onRandomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFieldValue('isRandom', checked);
    },
    [setFieldValue]
  );

  return (
    <div className={classes.container}>
      <List className={classes.listForm} component="div" disablePadding>
        {messageType === MessageType.Overhead && (
          <FormListCollapseItem
            labelKey="playMode"
            icon={<Icons.EventPlayModeIcon />}
            value={t(eventTypeLabelKey(values.eventType))}
          >
            <List component="div" disablePadding>
              <ListItem>
                <ListItemText
                  inset
                  primary={
                    <>
                      <RadioGroup value={values.eventType} onChange={onEventTypeChange}>
                        <FormControlLabel
                          value={EventType.AsSoonAs}
                          control={<Radio />}
                          label={t('playMode.afterCurrentSong')}
                        />
                        <FormControlLabel
                          value={EventType.ExactTime}
                          control={<Radio />}
                          label={t('playMode.interruptSong')}
                        />
                        <FormControlLabel value={EventType.Overlay} control={<Radio />} label={t('playMode.overlay')} />
                      </RadioGroup>
                    </>
                  }
                />
              </ListItem>
            </List>
          </FormListCollapseItem>
        )}

        <ListItem>
          <ListItemText inset>
            <FormControlLabel
              control={<Checkbox checked={values.isRandom} onChange={onRandomChange} />}
              label={t('randomMessagePlayback')}
            />
          </ListItemText>
        </ListItem>
        {messageType === MessageType.Overhead && (
          <ListItem>
            <ListItemText inset>
              <FormControlLabel
                control={<Checkbox checked={playAll} onChange={onPlayAllChange} />}
                label={t('playAllMessages')}
              />
              {!playAll && (
                <TextFieldInnerWrapper
                  variant="outlined"
                  type="number"
                  value={values.maxNumberOfMessages.toString()}
                  onChange={onMaxNumberOfMessagesChange}
                  helperText={t('numberOfMessagesToPlay')}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                />
              )}
            </ListItemText>
          </ListItem>
        )}

        {messageType === MessageType.Overhead && (
          <FormListCollapseItem
            labelKey="repeatFrequency"
            icon={<PlayFrequencyIcon />}
            value={t(repeatFrequencyLabelKey(values.repeatFrequency))}
          >
            <List component="div" disablePadding>
              <ListItem>
                <ListItemText
                  inset
                  primary={
                    <>
                      <RadioGroup value={values.repeatFrequency} onChange={onRepeatFrequencyChange}>
                        <FormControlLabel
                          value={RepeatFrequency.RepeatDaily}
                          control={<Radio value={RepeatFrequency.RepeatDaily} />}
                          label={t('playFrequency.onceDaily')}
                        />
                        <FormControlLabel
                          value={RepeatFrequency.RepeatHourly}
                          control={<Radio value={RepeatFrequency.RepeatHourly} />}
                          label={t('playFrequency.timeInterval')}
                        />
                        <FormControlLabel
                          value={RepeatFrequency.RepeatAfterNumberOfTracks}
                          control={<Radio value={RepeatFrequency.RepeatAfterNumberOfTracks} />}
                          label={t('playFrequency.playbackInterval')}
                        />
                        {values.repeatFrequency === RepeatFrequency.RepeatAfterNumberOfTracks && (
                          <div>
                            <Typography component="span">{t('randomMessagePlayback.every')}</Typography>
                            <OutlinedInput
                              value={values.repeatNumberOfTracks}
                              className={classes.playbackIntervalCountInput}
                              type="number"
                              inputProps={{
                                min: 1,
                                max: 100,
                              }}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const value =
                                  event.target.value !== undefined && event.target.value !== ''
                                    ? parseInt(event.target.value)
                                    : 1;
                                setFieldValue('repeatNumberOfTracks', Math.min(100, Math.max(1, value)));
                              }}
                            />
                            <Typography component="span">{t('randomMessagePlayback.playedMedia')}</Typography>
                          </div>
                        )}
                        {values.repeatFrequency === RepeatFrequency.RepeatHourly && (
                          <div>
                            <Typography component="span">{t('randomMessagePlayback.every')}</Typography>
                            <MinutesAndSecondsControl
                              onChange={(value: number) => setFieldValue('repeatInterval', value)}
                              value={values.repeatInterval}
                              min={1}
                              max={86340}
                            />
                          </div>
                        )}
                      </RadioGroup>
                    </>
                  }
                />
              </ListItem>
            </List>
          </FormListCollapseItem>
        )}

        <SchedulingListItems />
      </List>
    </div>
  );
};
