import { Icons } from 'components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import RemoveIcon from '@material-ui/icons/RemoveCircle';

import { useStyles } from './PlaylistMessagesToolbar.jss';

export interface PlaylistMessagesToolbarProps {
  onRemove: () => void;
  onDuplicate: () => void;
  onBrowse: () => void;
}

export const PlaylistMessagesToolbar: React.FunctionComponent<PlaylistMessagesToolbarProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Toolbar classes={{root: classes.messagesToolbar}}>
      {/* <Button variant='text' color='primary'>Add</Button> */}
      <Tooltip title={t<string>('duplicate')}>
        <IconButton onClick={props.onDuplicate}>
          <Icons.ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t<string>('remove')}>
        <IconButton onClick={props.onRemove}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t<string>('browse')}>
        <IconButton onClick={props.onBrowse}>
          <LibraryAddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
