import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AppBar, useTheme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from './PlaylistEditTabs.jss';

export interface PlaylistEditTabsProps {
  onSelectedTabChanged: (selectedTab: number) => void;
  selectedTab: number;
}

export const PlaylistEditTabs: React.FunctionComponent<PlaylistEditTabsProps> = React.memo((props) => {
  const [t] = useTranslation();
  const { selectedTab, onSelectedTabChanged } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const onTabsChanged = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      onSelectedTabChanged(value);
    },
    [onSelectedTabChanged]
  );

  return (
    <Paper elevation={0} color="default" className={classes.tabsContainer}>
      <Tabs
        value={selectedTab}
        onChange={onTabsChanged}
        variant={mobile ? 'fullWidth' : 'standard'}
        centered
        scrollButtons="auto"
      >
        <Tab label={t('messages')} value={0} />
        <Tab label={t('schedule')} value={1} />
        <Tab label={t('locations')} value={2} />
      </Tabs>
    </Paper>
  );
});
