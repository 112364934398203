import { AddressingRulesState, selectAddressingMediaId, selectAddressingRules } from 'addressing';
import { clearAddressingRules, processAddressingRulesStart } from 'addressing/duck/actions';
import useAddressing from 'addressing/hooks/addressing';
import useAddressingRules from 'addressing/hooks/addressingRules';
import { BottomSaveCancel } from 'components';
import { DirtyActions, DirtySelectors } from 'dirty';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { TextFieldInnerWrapper } from 'react-tools';
import { ZoneSelectors } from 'zone';
import { setSelectedPlaylistMessages } from 'zone/duck/actions';

import { useMediaQuery, useTheme } from '@material-ui/core';
import { EventPlaylistDto, MediaDto, MessageType } from '@models';

import Addressing from '../../addressing/Addressing';
import { PlaylistMessages } from './messages/PlaylistMessages';
import { useStyles } from './PlaylistEdit.jss';
import { PlaylistEditTabs } from './PlaylistEditTabs';
import { Schedule } from './schedule/Schedule';
import { ListMediaItem } from './types';

const mapMessages = (messages: MediaDto[]) => {
  return messages.map<ListMediaItem>((m, i) => ({
    ...m,
    localId: i + 1,
  }));
};

const checkEqual = (
  initialValues: any,
  values: any,
  initialMessages: any,
  messages: any,
  rules: AddressingRulesState
) => {
  const { messages: _, ...a } = initialValues as any;
  const { messages: __, ...b } = values as any;
  const ma = mapMessages(initialMessages);
  const mb = messages;
  let { originalData: ra, rawData: rb } = rules;
  rb = rb.filter((md) => md.deny !== null);

  const valuesAreEqual = isEqual(a, b);
  const messagesAreEqual = isEqual(ma, mb);
  const rulesAreEqual = isEqual(ra, rb);
  const equal = valuesAreEqual && messagesAreEqual && rulesAreEqual;

  return equal;
};

enum AddressingClearMode {
  RESET,
  REVERT,
}

export const PlaylistForm = ({
  playlistId,
  messageType,
  onCancel,
}: {
  playlistId: number;
  messageType?: MessageType;
  onCancel?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [internalMessages, setInternalMessages] = useState<ListMediaItem[]>([]);
  const classes = useStyles();
  const [t] = useTranslation();
  const context = useFormikContext<EventPlaylistDto>();
  const dispatch = useDispatch();
  const isSaving = useSelector(ZoneSelectors.selectIsSaving);
  const messages = useSelector(ZoneSelectors.selectPlaylistMessages);
  const dirty = useSelector(DirtySelectors.selectDirty);
  const confirmClicked = useSelector(DirtySelectors.selectConfirmClicked);
  const rules = useSelector(selectAddressingRules);
  const mediaId = useSelector(selectAddressingMediaId);

  useEffect(() => {
    setInternalMessages([]);
    dispatch(setSelectedPlaylistMessages([]));
  }, [playlistId]);

  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

  const resetValues = useCallback(
    (adressingClearMode: AddressingClearMode) => {
      context.setValues({ ...context.initialValues });
      setInternalMessages(mapMessages(messages));
      if (adressingClearMode === AddressingClearMode.RESET) {
        dispatch(clearAddressingRules());
      } else {
        dispatch(processAddressingRulesStart(rules.originalData, mediaId));
      }
    },
    [context, messages, rules, mediaId]
  );

  const cancel = useCallback(() => {
    resetValues(AddressingClearMode.REVERT);
    if (!playlistId) {
      dispatch(DirtyActions.setDirty(false));
      if (onCancel) {
        onCancel();
      }
    }
  }, [onCancel, playlistId, resetValues]);

  // When back button in browser.
  // useEffect first reached because dialog sets confirmedClicked `true`.
  // useEffect then reached because dialog sets dirty value `false`.
  // If we don't clean the form, we end in a loop of dialogs.
  // This justifies resetValues().
  useEffect(() => {
    if (confirmClicked) {
      resetValues(AddressingClearMode.RESET);
      return;
    }

    const nextDirty = !checkEqual(context.initialValues, context.values, messages, internalMessages, rules);
    if (nextDirty !== dirty) {
      dispatch(DirtyActions.setDirty(nextDirty));
    }
  }, [context, messages, internalMessages, dirty, confirmClicked, rules]);

  useEffect(() => {
    const newMessages = mapMessages(messages);
    setInternalMessages(newMessages);
    context.setFieldValue('messages', newMessages);
  }, [messages]);

  useEffect(() => {
    if (playlistId <= 0) {
      setInternalMessages([]);
    }
  }, [playlistId]);

  useEffect(() => {
    if (context) {
      context.setFieldValue('messages', internalMessages);
    }
  }, [internalMessages]);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, [playlistId]);

  return (
    <Form className={classes.playlistForm}>
      <div className={classes.playlistFormContent}>
        <Field name="name">
          {(fProps: FieldProps<string>) => (
            <TextFieldInnerWrapper
              fullWidth
              autoFocus
              name="name"
              value={fProps.field.value}
              onChange={fProps.field.onChange}
              placeholder={t('zonePlaylistTitlePlaceholder')}
              inputProps={{
                maxLength: 255,
              }}
              InputProps={{
                ref: inputRef,
                autoFocus: true,
                classes: {
                  input: !isSm ? classes.nameField : '',
                },
              }}
              error={fProps.meta.error !== undefined}
              helperText={fProps.meta.error}
            />
          )}
        </Field>

        <Field name="messages">{(fProps: FieldProps<any>) => <input type="hidden" />}</Field>

        <PlaylistEditTabs selectedTab={selectedTab} onSelectedTabChanged={setSelectedTab} />
        <div className={classes.tabPanel}>
          {selectedTab === 0 && (
            <PlaylistMessages
              messages={internalMessages}
              onMessagesChange={(messages) => {
                setInternalMessages(messages);
              }}
              playlistId={playlistId}
            />
          )}
          {selectedTab === 1 && <Schedule messageType={messageType} />}
          {selectedTab === 2 && <Addressing />}
        </div>
      </div>
      <BottomSaveCancel saveInProgres={isSaving} onCancel={cancel} />
    </Form>
  );
};
