import { MSG } from 'App.bootstrap';
import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';

import { MediaUpdateDto, MessageType } from '@models';

import * as actions from './actions';
import { LibraryApi } from './api';
import { MediaReferencesPayload } from './types';

export const fetchLibrary = (selectedMessageType: MessageType, workgroupId: number = MSG.workgroupId) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchLibraryRequest(workgroupId));
    try {
      const messages = await LibraryApi.getLibrary(workgroupId);
      dispatch(actions.fetchLibrarySuccess(workgroupId, messages, selectedMessageType));
    } catch (err) {}
  };
};

export const fetchMessage = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchMessageInfoRequest(id));
    try {
      const mediaDto = await LibraryApi.getMessageInfo(id);
      dispatch(actions.fetchMessageInfoSuccess(mediaDto));
    } catch (err) {}
  };
};

export const deleteMessages = (mediaIds: number[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.deleteMessagesRequest(mediaIds));
    try {
      var references = await LibraryApi.mediaReferences(<MediaReferencesPayload>{
        OnlySharedReferences: false,
        mediaIds: mediaIds,
      });
      console.log('media references:' + JSON.stringify(references));
      if (references) {
        var playlists = references['playlist'];
        var playlistNames = '';
        if (playlists !== undefined) {
          playlistNames = playlists.join(', ');
        }
        dispatch(pushNotification(i18next.t('denyDeleteMessageTitle'), 'error'));
      } else {
        await LibraryApi.deleteMessages(mediaIds);
        dispatch(actions.deleteMessagesSuccess(mediaIds));
      }
    } catch (err) {}
  };
};

export const saveMessage = (media: MediaUpdateDto) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.saveMessagesRequest(media));
    try {
      await LibraryApi.saveMessages(media);
      dispatch(actions.saveMessagesSuccess(media));
      dispatch(pushNotification(i18next.t('success.saveMessage'), 'success'));
    } catch (err) {
      dispatch(pushNotification(i18next.t('error.saveMessage'), 'error'));
    }
  };
};
