import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentFolderContainer: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    backFolderButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
    },
    currentFolderTitle: {},
    grow: {
      flexGrow: 1,
    },
    tableHeaderToolbar: {
      '& input[type=text]': {
        height: 'initial',
      },
      display: 'inline-flex',
      justifyItems: 'center',
    },
    quickSearchContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    tableHeaderToolbarGutters: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    newTableRow: {
      animation: `$highlightNewRow 4000ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes highlightNewRow': {
      '0%': {
        background: fade(theme.palette.warning.light, 0.4),
      },
      '100%': {
        background: 'inherit',
      },
    },
  })
);
