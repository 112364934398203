import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import SaveIcon from '@material-ui/icons/Done';

import { useStyles } from './BottomSaveCancel.jss';

export interface BottomSaveCancelProps {
  onSave?: () => void;
  saveInProgres?: boolean;
  onCancel?: () => void;
  hideCancel?: boolean;
}

export const BottomSaveCancel: React.FunctionComponent<BottomSaveCancelProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <>
      <Hidden xsDown>
        <Divider />
        <Toolbar className={classes.toolbar} disableGutters>
          <div className={classes.spacer} />
          <Button 
            type={props.onCancel ? "button" : "reset"}
            className={classes.toolbarButton} 
            onClick={props.onCancel} 
            variant="text"
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            className={classes.toolbarButton}
            variant="contained"
            onClick={props.onSave}
            startIcon={
              props.saveInProgres ? <CircularProgress size={24} className={classes.buttonProgress} /> : undefined
            }
          >
            {t('save')}
          </Button>
        </Toolbar>
      </Hidden>
      <Hidden smUp>
        <Fab type="submit" className={classes.fab} color="secondary" onClick={props.onSave}>
          <SaveIcon />
        </Fab>
      </Hidden>
    </>
  );
};
