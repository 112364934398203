export const layoutAreaName = 'layout';

export enum MainLayoutArea {
  LibraryOverhead = 0,
  LibraryOnHold = 1,
  ZonesOverhead = 2,
  ZonesOnHold = 3,
  Unauthorized = 4,
}

export interface LayoutState {
  selectedMainArea: MainLayoutArea;
  selectedItem: number;
  splashScreenShown: boolean;
}

export enum ActionTypes {
  MENU_SELECTION = 'MENU_SELECTION',
  MARK_SPLASH_SCREEN_AS_SHOWN = 'MARK_SPLASH_SCREEN_AS_SHOWN',
}
