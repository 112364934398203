import 'components/ag-grid/ag-theme.material-ui-dark.scss';

import { createMuiTheme, Theme } from '@material-ui/core';

export const moodTheme: Theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '*': {
          '&::-webkit-scrollbar': {
            width: '10px',
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.1)',
          },

          '&::webkit-scrollbar-track': {
            background: 'black',
          },

          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.3)',
            borderRadius: '5px',

            '&:hover': {
              background: 'rgba(255, 255, 255, 0.3)',
            },
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          WebkitBoxShadow: '0 0 0 0 transparent inset !important',
        },
      },
    },
  },
  palette: {
    primary: { main: '#ce102d' },
    secondary: { main: '#1b1d21' },
  },
  typography: {},
});

export const moodThemeDark: Theme = createMuiTheme({
  overrides: {
    MuiScopedCssBaseline: {
      root: {
        '@global': {
          '*': {
            '&::-webkit-scrollbar': {
              width: '8px',
              cursor: 'pointer',
              background: 'rgba(255, 255, 255, 0.1)',
            },

            '&::webkit-scrollbar-track': {
              background: 'black',
            },

            '&::-webkit-scrollbar-thumb': {
              background: 'rgba(255, 255, 255, 0.3)',
              borderRadius: '5px',

              '&:hover': {
                background: 'rgba(255, 255, 255, 0.3)',
              },
            },
          },
        },
      },
    },
    MuiInput: {
      input: {
        height: 'initial',
      },
    },
    MuiSnackbarContent: {
      message: {
        color: 'white'
      }
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#4fc3f7',
    },
    secondary: {
      main: '#f06292',
    },
  },
  typography: {},
});
