import i18next from 'i18next';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, DirtyState } from './types';

export const initialState: DirtyState = {
    value: false,
    dialog: {
        open: false,
        confirmClicked: false,
        cancelClicked: false,
        title: '',
        content: '',
        issuer: ''
    }
};

export const dirtyReducer: Reducer<DirtyState, ActionType<typeof Actions>> = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_DIRTY:
            return {
                ...state,
                value: action.payload.value
            }
        case ActionTypes.OPEN_DIALOG:
            return {
                ...state,
                dialog: {
                    open: true,
                    confirmClicked: false,
                    cancelClicked: false,
                    title: action.payload.title ? action.payload.title : i18next.t('dialog.discardChangesPrompt.title'),
                    content: action.payload.content ? action.payload.content : i18next.t('dialog.discardChangesPrompt.content'),
                    issuer: action.payload.issuer
                }
            };
        case ActionTypes.CONFIRM_CLICKED:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    open: false,
                    confirmClicked: true,
                    cancelClicked: false,
                    title: '',
                    content: ''
                }
            };
        case ActionTypes.CANCEL_CLICKED:
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    open: false,
                    confirmClicked: false,
                    cancelClicked: true,
                    title: '',
                    content: ''
                }
            }
        case ActionTypes.CLEAR_DIALOG: {
            return {
                ...state,
                dialog: {
                    ...state.dialog,
                    confirmClicked: false,
                    cancelClicked: false,
                    issuer: ''
                }
            }
        }
        default:
            return state;
    }
};